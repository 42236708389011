(function() {
  const docRef = $(document);
  const leaseOriginalRef = document.querySelector('.lease.lease--original');
  const leaseModifiedRef = $(".lease:not(.lease--modified)");

  const ContentEditableManager = function() {
    let $compile = null;
    let FreeTextService = null;
    let $rootScope = null;
    let LeaseEditorService = null;
    let $q = null;
    let $timeout = null;
    let CleanFreeTextService = null;
    let ApiService = null;
    this.inited = false;

    this.init = (
      _$compile,
      _FreeTextService,
      _$rootScope,
      _LeaseEditorService,
      _$q,
      _$timeout,
      _CleanFreeTextService,
      _ApiService
    ) => {
      if (this.inited) {
        return;
      }
      $compile = _$compile;
      FreeTextService = _FreeTextService;
      $rootScope = _$rootScope;
      LeaseEditorService = _LeaseEditorService;
      $q = _$q;
      $timeout = _$timeout;
      CleanFreeTextService = _CleanFreeTextService;
      ApiService = _ApiService;
      this.inited = true;
    };

    this.updateContainerVisibility = (sectionId)=>{
      const element = document.querySelector(`[section-id="${sectionId}"]`);
      if(element){
        const pidElement = element.closest('[pid]');
        if(pidElement){
          const deleted = pidElement.querySelectorAll('.deleted-paragraph',true);
          if( deleted.length > 0 ){
            pidElement.classList.add('deleted-container');

            if ($rootScope.isListItem($(pidElement)) && pidElement.previousElementSibling ) {
              pidElement.previousElementSibling.classList.add('deleted-container');
            }
          } else {
            pidElement.classList.remove('deleted-container');

            if ($rootScope.isListItem($(pidElement))) {
              pidElement.previousElementSibling.classList.remove('deleted-container');
            }
          }
          const tableElement = pidElement.closest('table');
          if(tableElement){
            const pidElements = tableElement.querySelectorAll('[free-text]',true);
            const deletedElements = tableElement.querySelectorAll('.deleted-paragraph',true).
              filter((node)=>{return node.tagName !== 'TABLE'});
            if(pidElements.length === deletedElements.length){
              tableElement.classList.add('deleted-container');
            }
            else{
              tableElement.classList.remove('deleted-container');
            }
          }
        }
      }
    }

    function triggerChange(element) {
      $(element).trigger("change");
    }

    function insertChar(char, color) {
      var node;
      if (!color) {
        node = document.createTextNode(char);
      } else {
        node = document.createElement("span");
        node.innerText = char;
        node.style.color = color;
      }
      insertAtCaret(node);
    }


    this.isCaretAtParagraphEnd = () => {
      let isSelecting = LeaseEditorService.isSelecting();

      const el = document.createTextNode("!@#$");
      let anchor = null;
      let firstFreeText = null;
      let sel = rangy.getSelection();
      let selection = rangy.saveSelection();

      if (isSelecting) {
        let range = sel.getRangeAt(0);
        let ret = false;
        LeaseEditorService.enableEditingForSegment(sel.focusNode.closest('.editable'));
        range.collapse(true);
        range.select();
        insertAtCaret(el, false);
        anchor = el.getTopAnchor();
        firstFreeText = anchor.querySelector('[free-text]');
        ret = firstFreeText && firstFreeText.innerText.trim().replace(/﻿/g, '').endsWith(el.textContent);

        el.remove();
        rangy.restoreSelection(selection, true);

        return ret;
      } else {
        insertAtCaret(el, false);
        
        if (el.closest) {
          anchor = el.closest("p,h1,h2,h3,h4,h5,h6");
        } else {
          anchor = el.parentElement.closest("p,h1,h2,h3,h4,h5,h6");
        }
        
        let ret;
        if(anchor){
          firstFreeText = anchor.querySelector('[free-text]');
          if (
            firstFreeText &&
            firstFreeText.innerText.trim().replace(/﻿/g, '').endsWith(el.textContent)
          ) {
            ret = true;
          } else {
            ret = false;
          }
        } else {
          ret = false;
        }

        el.remove();
        rangy.restoreSelection(selection, true);

        return ret;
      }
    };

    this.isCaretAtStartContainer = ()=>{
      let isSelecting = LeaseEditorService.isSelecting();

      const el = document.createTextNode("!@#$");
      let anchor = null;
      let firstFreeText = null;
      let sel = rangy.getSelection();
      let selection = rangy.saveSelection();

      if (isSelecting) {
        let range = sel.getRangeAt(0);
        let ret = false;
        LeaseEditorService.enableEditingForSegment(sel.focusNode.closest('.editable'));
        range.collapse(true);
        range.select();
        insertAtCaret(el, false);
        anchor = el.getTopAnchor();
        firstFreeText = anchor.querySelector('[free-text]');
        ret = firstFreeText && firstFreeText.innerText.trim().replace(/﻿/g, '').replace(/​/g, '').startsWith(el.textContent);

        el.remove();
        rangy.restoreSelection(selection, true);

        return ret;
      } else {
        insertAtCaret(el, false);
        anchor = el.parentElement.closest("p, h1, h2, h3, h4, h5, h6");

        const textNodes = getAllTextNodes(anchor);

        let ret;
        if(anchor && anchor.querySelector){
          if (anchor.getAttribute("pid")) {
            firstFreeText = anchor.querySelector('[free-text]');
          } else {
            firstFreeText = anchor;
          }

          if (
            firstFreeText &&
            textNodes.indexOf(el) === 1 && // Skip `.rangySelectionBoundary`
            firstFreeText.innerText.trim().replace(/﻿/g, '').replace(/​/g, '').startsWith(el.textContent)
          ) {
            ret = true;
          } else {
            ret = false;
          }
        } else {
          ret = false;
        }

        el.remove();
        rangy.restoreSelection(selection, true);

        return ret;
      }
    }

    this.isCaretAtEndContainer = () => {
      let isSelecting = LeaseEditorService.isSelecting();

      const el = document.createTextNode("!@#$");
      let sel = rangy.getSelection();
      let selection = rangy.saveSelection();

      if (isSelecting) {
        let range = sel.getRangeAt(0);
        let ret = false;
        LeaseEditorService.enableEditingForSegment(sel.focusNode.closest('.editable'));
        range.collapse(false);
        range.select();
        insertAtCaret(el, false);
        const anchor = el.getTopAnchor();

        if (anchor && anchor.innerText) {
          let text = anchor.innerText
            .trim()
            .replace(/\u200B/g, "") // ZERO WIDTH SPACE
            .replace(/\u200D/g, "") // ZERO WIDTH JOINER
            .replace(/\ufeff/g, ""); // ZERO WIDTH NO-BREAK SPACE;
          ret = text.endsWith(el.textContent);
        }

        el.remove();
        rangy.restoreSelection(selection, true);

        return ret;
      } else {
        insertAtCaret(el, false);
        const anchor = el.getTopAnchor();

        try {
          let ret = anchor.innerText
            .trim()
            .replace(/\u200B/g, "") // ZERO WIDTH SPACE
            .replace(/\u200D/g, "") // ZERO WIDTH JOINER
            .replace(/\ufeff/g, "")
            .endsWith(el.textContent);
          el.remove();
          rangy.restoreSelection(selection, true);
          return ret;
        } catch (e) {
          el.remove();
          rangy.restoreSelection(selection, true);
          return false;
        }
      }
    };

    async function insertAtCaret(element, shouldSave = true) {
      
      function insertAtCaretInternal() {
        var sel;
        var range;
        
        sel = rangy.getSelection();

        var contentEditable = LeaseEditorService.findClosestContentEditable(
          sel.anchorNode
        );

        let anchorElement = sel.anchorNode;
        if (sel.anchorNode.nodeType === Node.TEXT_NODE) {
          anchorElement = sel.anchorNode.parentElement;
        }

        if (anchorElement.classList.contains("SKIP")) {
          anchorElement.parentElement.insertBefore(element, anchorElement.nextElementSibling);
          $rootScope.placeCaretAtEnd(element);
        } else {
          if (sel.getRangeAt && sel.rangeCount) {
            LeaseEditorService.enableEditingForSegment(contentEditable);
            range = sel.getRangeAt(0);
            range.insertNode(element);
            range.collapseAfter(element);
            range.select();
          }
        }
      }

      if (LeaseEditorService.isSelecting()) {
        // Delete the text the user selected before inserting the element
        await $rootScope.deleteSelection(insertAtCaretInternal, true);
      } else {
        insertAtCaretInternal();

        if (shouldSave) {
          triggerChange(element);
        }
      }
    }

    this.insertAtCaret = insertAtCaret;

    function saveDefaultProvision(
      scopeVar,
      attrs,
      scope,
      defaultHtml,
      override,
      _element
    ) {
      if (!scopeVar.editingEnabled) {
        return;
      }

      if ($rootScope.defaultProvisions[attrs.sectionId] && !override) {
        return;
      }

      var htmlWithStyle;
      const isManageForm = window.location.href.indexOf("/manage") !== -1;

      if (isManageForm) {
        if (_element) {
          htmlWithStyle = $rootScope.inlineComputedStyles(_element);
        } else {
          htmlWithStyle =
            $rootScope.defaultProvisions[attrs.sectionId].htmlWithStyle;
        }
      }

      if (override) {
        defaultHtml =
          "<default-provision>" + defaultHtml + "</default-provision>";
        // We have to recompile the node since it might contain angular directives
        const defaultElement = $compile(defaultHtml)(scope);
        scope.$apply();
        defaultHtml = $(defaultElement).get(0).innerHTML;
        defaultHtml = LeaseEditorService.flattenSpecialVariables(defaultHtml);
      }

      $rootScope.defaultProvisions[attrs.sectionId] = {
        html: defaultHtml,
        htmlWithStyle: htmlWithStyle,
        hashCode: LeaseEditorService.prepareForComparison(
          defaultHtml
        ).hashCode()
      };
    }

    function flattenAndSave(element, scopeVar, attrs, scope, ngModel) {
      setTimeout(function() {
        setTimeout(function() {
          scopeVar.maintainNestedParagraphsIndex();
          LeaseEditorService.flattenSpecialVariables(element);
          saveDefaultProvision(
            scopeVar,
            attrs,
            scope,
            $(element).get(0).innerHTML,
            false,
            element
          );
          if (ngModel.$modelValue) {
            if (
              !element[0].closest(".diff-item") &&
              !element[0].querySelector(".diff-item")
            ) {
              element.get(0).innerHTML = ngModel.$viewValue.text || "";
            }
            $compile(element.contents())(scope);
            setTimeout(function() {
              LeaseEditorService.flattenSpecialVariables(element);
              // This saves the overrides as a default provision
              if (scopeVar.overrides) {
                var override = _.find(scopeVar.overrides, {
                  sectionId: parseInt(attrs.sectionId)
                });

                if (override) {
                  saveDefaultProvision(
                    scopeVar,
                    attrs,
                    scope,
                    override.text,
                    true
                  );
                }
              }
            }, 0);
          }

          //resolve promise of inserted-list-item when compiling is done.
          if (
            $rootScope.listItemsPromises &&
            $rootScope.listItemsPromises[attrs.sectionId]
          ) {
            $rootScope.listItemsPromises[attrs.sectionId].resolve();
          }
        }, 0);
      }, 0);
    }

    function handleCtrlShift0(e) {
      scopeVar.showRevertChanges = !scopeVar.showRevertChanges;
      scopeVar.safeApply();
    }

    function handleSelectAll(e) {
      var sel = rangy.getSelection();
      sel.nativeSelection.modify("extend", "forward", "character");
      LeaseEditorService.disableEditingForSegment(sel.focusNode);
    }

    function handleUndo(e) {
      if (!e) {
        console.error("handleUndo");
      }
      e.preventDefault();

      scopeVar.pauseSearch();

      scopeVar.execute("undo");
    }

    function handleRedo(e) {
      if (!e) {
        console.error("handleRedo");
      }
      e.preventDefault();

      scopeVar.pauseSearch();

      scopeVar.execute("redo");
    }

    function handleFind(e) {
      if (!e) {
        console.error("handleFind");
      }
      e.preventDefault();

      scopeVar.toggleSearch(true);

      window.track.event(new ToggleDocumentSearchEvent({
        context: $rootScope.getContext()
      }));
    }

    function handleEditorFind(e) {
      if (!e) {
        console.error("handleEditorFind");
      }
      e.preventDefault();

      const button = document.querySelector(".editor-search__show-interface");
      button.click();
    }

    function handleAlign(e, alignment) {
      if (!e) {
        console.error("handleAlign");
      }
      e.preventDefault();

      if (!scopeVar.indentDisable) {
        scopeVar.align(alignment);
      }
    }

    async function handleCut(e) {
      if (LeaseEditorService.isSelecting()) {
        const sel = rangy.getSelection();
        const range = sel.getRangeAt(0);
        const rangeCount = sel.rangeCount;
        const rangeToString = range.toString();
        const commonAncestorContainer = range.commonAncestorContainer;
  
        // First, let's copy the selection to the clipboard
        if (commonAncestorContainer.closest(".lease")) {
          LeaseEditorService.disableEditingForSegment(sel.anchorNode);
          await LeaseEditorService.copySelection();
          window.track.event(new CutEvent({
            context: $rootScope.getContext(),
          }));
          e.preventDefault();

          // Then, delete the selection
          if (rangeCount > 0) {
            if (rangeToString.length > 0) {
              await $rootScope.deleteSelection(null, null, true);
            }
          }
        }
      }
    }

    function handleCopy(e) {
      if (LeaseEditorService.isSelecting()) {
        const sel = rangy.getSelection();
        const range = sel.getRangeAt(0);
        const commonAncestorContainer = range.commonAncestorContainer;
  
        if (commonAncestorContainer.closest(".lease")) {
          LeaseEditorService.copySelection();
          window.track.event(new CopyEvent({
            context: $rootScope.getContext(),
          }));
          e.preventDefault();
        }
      }
    }

    function reserveCaretPosition(element) {
      if (!window.reservedCaretPosition) {
        window.reservedCaretPosition = $(element).caret("offset").left;
      }
    }

    function getAllTextNodes(element) {
      // TODO: should be replaced with childNodes
      let iterator;
      let result = [];
      
      if (!element) {
        return result;
      }
      
      let walk = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      while ((iterator = walk.nextNode())) {
        if (
          iterator.parentElement.closest(".editable") &&
          !iterator.parentElement.closest(".lp-hide-element")
        ) {
          result.push(iterator);
        }
      }

      return result;
    }

    function getAllTextNodesParents(element) {
      // TODO: should be replaced with childNodes
      let iterator;
      let result = [];
      let walk = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT,
        null,
        false
      );

      while ((iterator = walk.nextNode())) {
        if (
          iterator.parentElement.closest(".editable") &&
          !iterator.parentElement.closest(".lp-hide-element")
        ) {
          result.push(iterator.parentElement);
        }
      }

      return result;
    }

    function getLastTextNode(element, skipElement) {
      let children = element.childNodes;
      let lastChild = children[children.length - 1];
      let lastTextNode = null;
      let tempNode;
      if (lastChild && lastChild.nodeType === Node.TEXT_NODE) {
        return lastChild;
      }
      for (let index = 0; index < children.length; ++index) {
        tempNode = children[index];

        if (tempNode === skipElement) {
          break;
        }
        if (tempNode.nodeType === Node.TEXT_NODE) {
          lastTextNode = tempNode;
        } else {
          let result = getLastTextNode(tempNode, skipElement);
          lastTextNode = result ? result : lastTextNode;
        }
      }
      return lastTextNode;
    }

    function locateCaretAtEndOfSelection() {
      var node;
      var sel = rangy.getSelection();
      var range = sel.getRangeAt(0);
      node = sel.isBackwards() ? sel.anchorNode : sel.focusNode;
      LeaseEditorService.enableEditingForSegment(
        LeaseEditorService.findClosestContentEditable(node)
      );
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    }

    function isIndentedParagraph() {
      let sel = rangy.getSelection();
      let focusNode = sel.focusNode;

      if (focusNode.nodeType === Node.TEXT_NODE) {
        focusNode = focusNode.parentElement;
      }

      if (!focusNode.classList.contains("new-paragraph")) {
        focusNode = LeaseEditorService.findClosestContainer(sel.focusNode).get(
          0
        );
      }

      if (
        focusNode.getAttribute("data-paragraph-indent") != null && focusNode.getAttribute("data-paragraph-indent") !== "0" ||
        focusNode.getAttribute("data-text-indent") != null && focusNode.getAttribute("data-text-indent") !== "0"
      ) {
        return true;
      }

      return false;
    }

    function resetReservedCaretPosition(e) {
      if (!e) {
        console.error("resetReservedCaretPosition");
      }
      if (
        e.keyCode !== window.ENUMS.KEYS.UP_ARROW &&
        e.keyCode !== window.ENUMS.KEYS.DOWN_ARROW
      )
        window.reservedCaretPosition = undefined;
    }

    function maintainNewParagraphsStyles(element) {
      const container = LeaseEditorService.findClosestContainer(element).get(0);
      const newParagraphs = container.querySelectorAll(".new-paragraph");
      const originalMarginTop = container.style.marginTop;
      const originalMarginBottom = container.style.marginBottom;

      for (
        let paragraphIndex = 0;
        paragraphIndex < newParagraphs.length;
        paragraphIndex++
      ) {
        let paragraph = newParagraphs[paragraphIndex];

        if (paragraphIndex === 0) {
          paragraph.classList.add("new-paragraph--first");
          paragraph.setAttribute("data-original-margin-top", originalMarginTop);
          if (parseInt(originalMarginBottom) > parseInt(originalMarginTop)) {
            paragraph.style.marginTop = originalMarginBottom;
          }
        } else {
          paragraph.classList.remove("new-paragraph--first");
          paragraph.removeAttribute("data-original-margin-top");
          paragraph.style.marginTop = originalMarginTop;
        }
      }
    }

    function hasProvisionChanged(ngModel) {
      return !(
        !ngModel.$modelValue ||
        (ngModel.$modelValue && ngModel.$modelValue.type === "override") ||
        (ngModel.$modelValue && ngModel.$modelValue.type === "paste")
      );
    }

    function moveOriginalHardReturn(container) {
      const originalHardReturn = container.querySelector(
        "hard-return:not([class])"
      );
      const newParagraphs = container.querySelectorAll(".new-paragraph");

      if (originalHardReturn && newParagraphs.length > 0) {
        newParagraphs[0].parentElement.insertBefore(
          originalHardReturn,
          newParagraphs[0]
        );
        originalHardReturn.classList.add("hard-return--original");
      }
    }

    function revertOriginalHardReturn(container) {
      const originalHardReturn = container.querySelector(
        "hard-return.hard-return--original"
      );

      if (originalHardReturn) {
        container.insertBefore(originalHardReturn, null);
        originalHardReturn.removeAttribute("class");
        originalHardReturn.innerText = "";
      }
    }

    function isCaretInEndParagraph() {
      let sel = rangy.getSelection();
      let container = LeaseEditorService.findClosestContainer(
        sel.focusNode
      ).get(0);
      let result = false;

      if (container.classList.contains("new-paragraph")) {
        if (container.textContent === "﻿" || container.textContent === "​") {
          result = true;
        }

        container = LeaseEditorService.findClosestContainerAbove(container).get(
          0
        );
      }

      if (!result) {
        const marker = document.createTextNode("!@#$%");
        insertAtCaret(marker);
        const textNodes = getAllTextNodes(container);
        const markerIndex = textNodes.indexOf(marker);

        if (markerIndex === textNodes.length - 1) {
          result = true;
        } else {
          const markerContainer = LeaseEditorService.findClosestContainer(
            textNodes[markerIndex]
          ).get(0);
          const nextTextContainer = LeaseEditorService.findClosestContainer(
            textNodes[markerIndex + 1]
          ).get(0);

          if (
            markerContainer !== nextTextContainer &&
            nextTextContainer.classList.contains("new-paragraph")
          ) {
            result = true;
          }
        }

        marker.remove();
      }

      return result;
    }

    function handleSoftBreak(e, isBullets) {
      scopeVar.pauseSearch();

      if (isBullets) {
        return;
      }

      e.preventDefault();

      LeaseEditorService.skipMarkers();

      // Insert line break
      var softReturn = document.createElement("soft-return");
      var br = document.createElement("br");
      insertAtCaret(softReturn);
      insertAtCaret(br);
      insertAtCaret(
        document.createTextNode(window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE)
      );

      window.track.event(new EnterSoftBreakEvent({
        context: $rootScope.getContext()
      }));
    }

    function flattenNestedParagraphs(anchorOffset, element, scopeVar) {
      const container = LeaseEditorService.findClosestContainer(element).get(0);
      const newParagraphs = container.querySelectorAll(".new-paragraph");

      for (
        let paragraphIndex = newParagraphs.length - 1;
        paragraphIndex >= 0;
        paragraphIndex--
      ) {
        let paragraph = newParagraphs[paragraphIndex];
        let parent = paragraph.parentElement.closest(".new-paragraph, [data-nested-paragraph-index]");
        if (parent) {
          parent.parentElement.insertBefore(paragraph, parent.nextSibling);
        }
      }

      if (!scopeVar.diffgram.on) {
        scopeVar.maintainNestedParagraphsIndex();
      }
    }

    async function handleHardSpace(e) {
      e.preventDefault();

      var hardSpace = document.createTextNode("\u00A0");

      if (LeaseEditorService.isSelecting()) {
        // Delete the text the user selected before inserting the hard space
        await $rootScope.deleteSelection(() => {
          insertAtCaret(hardSpace);
        }, true);
      } else {
        insertAtCaret(hardSpace);
      }

      window.track.event(new EnterHardSpaceEvent({
        context: $rootScope.getContext()
      }));
    }

    function handleNormalKey(e, scopeVar, isBullets) {
      scopeVar.pauseSearch();

      switch (e.keyCode) {
        case window.ENUMS.KEYS.TAB:
        case window.ENUMS.KEYS.ENTER:
        case window.ENUMS.KEYS.SHIFT:
        case window.ENUMS.KEYS.CTRL:
        case window.ENUMS.KEYS.ALT:
        case window.ENUMS.KEYS.PAUSE:
        case window.ENUMS.KEYS.CAPS_LOCK:
        case window.ENUMS.KEYS.ESCAPE:
        case window.ENUMS.KEYS.INSERT:
        case window.ENUMS.KEYS.LEFT_META:
        case window.ENUMS.KEYS.RIGHT_META:
        case window.ENUMS.KEYS.F1:
        case window.ENUMS.KEYS.F2:
        case window.ENUMS.KEYS.F3:
        case window.ENUMS.KEYS.F4:
        case window.ENUMS.KEYS.F5:
        case window.ENUMS.KEYS.F6:
        case window.ENUMS.KEYS.F7:
        case window.ENUMS.KEYS.F8:
        case window.ENUMS.KEYS.F9:
        case window.ENUMS.KEYS.F10:
        case window.ENUMS.KEYS.F11:
        case window.ENUMS.KEYS.F12:
        case window.ENUMS.KEYS.NUM_LOCK:
        case window.ENUMS.KEYS.SCROLL_LOCK: {
          return;
        }
      }

      if (LeaseEditorService.isSelecting()) {
        e.preventDefault();

        // The first keystroke is not being persisted, so we do it manually write the
        // letter the user clicked on at the current caret position
        LeaseEditorService.skipMarkers();
        if ($rootScope.adminMode) {
          insertChar(e.key, window.CONSTS.ADMIN_COLOR);
        } else {
          insertChar(e.key);
        }
        
        let sel = rangy.getSelection();
        
        if (!LeaseEditorService.isSelecting()) {
          if (sel.anchorNode) {
            sel.anchorNode.normalize();
            sel = window.getSelection();
            if (
              sel.focusNode.textContent.length > 1 &&
              sel.focusNode.textContent.indexOf(
                window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE
              ) > 0 
            ) {
              if(sel.focusNode.deleteData){
                sel.focusNode.deleteData(
                  sel.focusNode.textContent.indexOf(
                    window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE
                  ),
                  1
                );
              }
            }

            if (
              sel.focusNode.textContent.length > 1 &&
              sel.focusNode.textContent.indexOf(
                window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE
              ) > 0 
            ) {
              if(sel.focusNode.deleteData){
                sel.focusNode.deleteData(
                  sel.focusNode.textContent.indexOf(
                    window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE
                  ),
                  1
                );
              }
            }
          }
        }
      } else {
        LeaseEditorService.skipNonWidthChar("forward");

        const sel = rangy.getSelection();
        let anchorElement = sel.anchorNode;
        if (sel.anchorNode.nodeType === Node.TEXT_NODE) {
          anchorElement = sel.anchorNode.parentElement;
        }

        if (anchorElement.classList.contains("SKIP")) {
          const span = anchorElement.cloneNode(true);
          span.classList.remove("SKIP");
          anchorElement.parentElement.insertBefore(span, anchorElement.nextElementSibling);
          $rootScope.placeCaretAtEnd(anchorElement);
        }

        if (isBullets) {
          LeaseEditorService.adjustBulletsColor();
        }

        if ($rootScope.adminMode) {
          LeaseEditorService.setContentEditableColor(window.CONSTS.ADMIN_COLOR);
        }
      }
    }

    function handleToggleBold(e, element, scopeVar) {
      e.preventDefault();

      scopeVar.pauseSearch();

      if (
        !$rootScope.decorationButtonsDisabled &&
        window.getSelection().type === "Range"
      ) {
        scopeVar.btns.bold.state = !scopeVar.btns.bold.state;
        scopeVar.applyDecoration(scopeVar.btns.bold.command);
        triggerChange(element);
      } else if (window.getSelection().type === "Caret") {
        scopeVar.execCommand("bold");
      }
    }

    function handleToggleItalic(e, element, scopeVar) {
      e.preventDefault();

      scopeVar.pauseSearch();

      if (
        !$rootScope.decorationButtonsDisabled &&
        window.getSelection().type === "Range"
      ) {
        scopeVar.btns.italic.state = !scopeVar.btns.italic.state;
        scopeVar.applyDecoration(scopeVar.btns.italic.command);
        triggerChange(element);
      } else if (window.getSelection().type === "Caret") {
        scopeVar.execCommand("italic");
      }
    }

    function handleToggleUnderline(e, element, scopeVar) {
      e.preventDefault();

      scopeVar.pauseSearch();

      if (
        !$rootScope.decorationButtonsDisabled &&
        window.getSelection().type === "Range"
      ) {
        scopeVar.btns.underline.state = !scopeVar.btns.underline.state;
        scopeVar.applyDecoration(scopeVar.btns.underline.command);
        triggerChange(element);
      } else if (window.getSelection().type === "Caret") {
        scopeVar.execCommand("underline");
      }
    }

    function handleTab(e, scopeVar) {
      e.preventDefault();
      scopeVar.pauseSearch();
      scopeVar.indent(0.5, null);
    }

    function handleUnTab(e, scopeVar) {
      e.preventDefault();
      scopeVar.pauseSearch();
      scopeVar.indent(-0.5, null);
    }

    function handleSmartQuotes(e) {
      // If form configured to support smart quotes
      if (scopeVar.features.supportSmartQuotes) {
        const sel = rangy.getSelection();
        const anchor = sel.anchorNode.getTopAnchor();
        const block = anchor.getBlockData();
        const textNodes = block.getAllEditableTextNodes();
        const index = textNodes.indexOf(sel.anchorNode);
        let charIndex;
  
        if (e.key === '"') {
          for (let i = index; i >= 0; i--) {
            charIndex = textNodes[i].length - 1;

            if (textNodes[i] === sel.anchorNode) {
              charIndex = sel.anchorOffset;
            }

            for (let j = charIndex; j >= 0; j--) {
              if (textNodes[i].textContent[j] === ('“')) {
                e.preventDefault();
                document.execCommand('insertText', false, "”");
                return;
              } else if (textNodes[i].textContent[j] === ('”')) {
                e.preventDefault();
                document.execCommand('insertText', false, "“");
                return;
              }
            }
          }
  
          e.preventDefault();
          document.execCommand('insertText', false, "“");
        } else if (e.key === "'") {
          if (sel.anchorNode.textContent[sel.anchorOffset - 1] === ' ') {
            e.preventDefault();
            document.execCommand('insertText', false, "‘");
            return;
          } else {
            e.preventDefault();
            document.execCommand('insertText', false, "’");
          }
        }
      }
    }

    function handleJoinParagraphsForward(e, element) {
      const sel = rangy.getSelection();
      let originParagraph = sel.focusNode;
      let container = LeaseEditorService.findClosestContainer(
        originParagraph
      ).get(0);
      let rootContainer = container;

      if (LeaseEditorService.isEmpty(rootContainer, true)) {
        revertOriginalHardReturn(rootContainer);
        rootContainer.remove();
        return;
      }

      if (container.classList.contains("list-item-paragraph")) {
        return;
      }

      if (rootContainer.classList.contains("new-paragraph")) {
        rootContainer = LeaseEditorService.findClosestContainerAbove(
          rootContainer
        ).get(0);
      }

      if (rootContainer.querySelectorAll(".new-paragraph").length === 1) {
        revertOriginalHardReturn(rootContainer);
      }

      if (originParagraph.nodeType === Node.TEXT_NODE) {
        originParagraph = originParagraph.parentElement;
      }

      if (!originParagraph.classList.contains("new-paragraph")) {
        originParagraph = LeaseEditorService.findClosestContainer(
          originParagraph
        ).get(0);
      }

      let paragraphToRemove;

      if (!container.classList.contains("new-paragraph")) {
        paragraphToRemove = container.querySelector(".new-paragraph");
      } else {
        paragraphToRemove = originParagraph.nextSibling;
      }

      if (paragraphToRemove) {
        let node;
        let caretNode;

        if (!container.classList.contains("new-paragraph")) {
          caretNode = sel.focusNode;
        } else {
          caretNode =
            originParagraph.childNodes[originParagraph.childNodes.length - 1];
        }

        if (
          caretNode.nodeName &&
          caretNode.nodeName.toLowerCase() === "hard-return"
        ) {
          caretNode = caretNode.previousSibling;
        }

        while (
          caretNode.nodeType !== Node.TEXT_NODE &&
          caretNode.childNodes.length > 0
        ) {
          caretNode = caretNode.childNodes[0];
        }

        while (paragraphToRemove.childNodes.length > 0) {
          node = paragraphToRemove.childNodes[0];
          node.remove();

          if (
            node.nodeType === Node.ELEMENT_NODE &&
            node.nodeName.toLowerCase() === "hard-return"
          ) {
            continue;
          }

          if (!container.classList.contains("new-paragraph")) {
            if (sel.focusNode.nodeType === Node.TEXT_NODE) {
              sel.focusNode.parentElement.insertBefore(node, null);
            } else {
              const originalHardReturn = originParagraph.querySelector(
                "hard-return.hard-return--original"
              );

              if (originalHardReturn) {
                originalHardReturn.parentElement.insertBefore(
                  node,
                  originalHardReturn
                );
                caretNode = originalHardReturn.previousElement;
              } else {
                sel.focusNode.insertBefore(node, null);
              }
            }
          } else {
            if (originParagraph.classList.contains("new-paragraph")) {
              originParagraph.insertBefore(
                node,
                originParagraph.childNodes[
                  originParagraph.childNodes.length - 1
                ]
              );
            } else {
              originParagraph.insertBefore(node, null);
            }
          }
        }

        paragraphToRemove.remove();

        $rootScope.placeCaretAtEnd(caretNode);

        triggerChange(element);
      }
    }

    function handleJoinParagraphsBackwards(e, element) {
      const sel = rangy.getSelection();
      let paragraphToRemove = sel.focusNode;
      let container = LeaseEditorService.findClosestContainerAbove(
        sel.focusNode
      ).get(0);

      if (container.classList.contains("list-item-paragraph")) {
        return;
      }

      if (container.classList.contains("new-paragraph")) {
        container = LeaseEditorService.findClosestContainerAbove(container).get(
          0
        );
      }

      if (container.querySelectorAll(".new-paragraph").length === 1) {
        revertOriginalHardReturn(container);
      }

      if (paragraphToRemove.nodeType === Node.TEXT_NODE) {
        paragraphToRemove = paragraphToRemove.parentElement;
      }

      if (!paragraphToRemove.classList.contains("new-paragraph")) {
        paragraphToRemove = LeaseEditorService.findClosestContainer(
          paragraphToRemove
        ).get(0);
      }

      if (paragraphToRemove.nodeName === 'P' &&
          !paragraphToRemove.classList.contains("new-paragraph")) {
        return;
      }

      let targetParagraph = paragraphToRemove.previousSibling;
      let caretNode;

      if (
        targetParagraph &&
        targetParagraph.nodeName &&
        targetParagraph.nodeName.toLowerCase() === "hard-return"
      ) {
        targetParagraph = targetParagraph.previousSibling;
      }

      if (!targetParagraph) {
        let parent = paragraphToRemove.closest('span');
        while (parent) {
          targetParagraph = getLastTextNode(parent, paragraphToRemove);
          if (targetParagraph) {
            break;
          }
          parent = parent.parentElement;
          if (parent) {
            parent = parent.closest('span');
          }
        }
      }

      if (targetParagraph && targetParagraph.nodeName === "PAGE-BREAK") {
        targetParagraph.remove();
        targetParagraph = undefined;
      }


      if (targetParagraph) {
        let node;
        caretNode =
          targetParagraph.childNodes[targetParagraph.childNodes.length - 1];

        if (targetParagraph.nodeType === Node.TEXT_NODE) {
          caretNode = targetParagraph;
        }

        if (
          caretNode.nodeName &&
          caretNode.nodeName.toLowerCase() === "hard-return"
        ) {
          caretNode = caretNode.previousSibling;
        }

        while (
          caretNode.nodeType !== Node.TEXT_NODE &&
          caretNode.childNodes.length > 0
        ) {
          caretNode = caretNode.childNodes[caretNode.childNodes.length - 1];
        }

        if (paragraphToRemove.childNodes.length > 0) {
          let node = paragraphToRemove.childNodes[0];

          if (node.textContent.startsWith("﻿") || node.textContent.startsWith("​")) {
            if (node.nodeType !== Node.TEXT_NODE) {
              const textNodes = getAllTextNodes(node);
              node = textNodes[0];
            }

            node.textContent = node.textContent.substring(
              1,
              node.textContent.length
            );
          }
        }

        while (paragraphToRemove.childNodes.length > 0) {
          node = paragraphToRemove.childNodes[0];
          let _computedStyle;
          if (node.nodeType === Node.ELEMENT_NODE) {
            _computedStyle = JSON.parse(JSON.stringify(getComputedStyle(node)));
          }
          node.remove();

          if (
            node.nodeType === Node.ELEMENT_NODE &&
            node.nodeName.toLowerCase() === "hard-return"
          ) {
            continue;
          }

          if (node.nodeType === Node.ELEMENT_NODE) {
            setElementStyles(node, _computedStyle,
            {
              fontWeight: 'normal',
              fontStyle: 'normal',
              textDecoration: 'none',
              backgroundColor: 'rgb(256, 256, 256)',
              color: 'rgb(0, 0, 0)',
            });
          }

          if (targetParagraph.nodeType === Node.TEXT_NODE) {
            targetParagraph.parentElement.insertBefore(
              node,
              targetParagraph.nextSibling
            );
            targetParagraph = node;
          } else {
            if (targetParagraph.classList.contains("new-paragraph")) {
              let childNode =
                targetParagraph.childNodes[
                  targetParagraph.childNodes.length - 1
                ];
              targetParagraph.insertBefore(node, childNode);
              targetParagraph = node;
            } else {
              targetParagraph.parentElement.insertBefore(
                node,
                targetParagraph.nextSibling
              );
              targetParagraph = node;
            }
          }
        }
      } else {
        caretNode = LeaseEditorService.findClosestContainerAbove(
          paragraphToRemove
        );
      }

      paragraphToRemove.remove();

      $rootScope.placeCaretAtEnd(caretNode);

      triggerChange(element);
    }

    function isFirstLineInContainer(caretOffset, isBullets) {
      var sel = rangy.getSelection();
      var currTopY = caretOffset.top;
      var result = false;
      var THRESHOLD_ERROR = 4;

      const contentEditable = $(sel.focusNode).closest(".editable");
      let container = LeaseEditorService.findClosestContainer(
        sel.focusNode
      ).get(0);

      if (isBullets) {
        const bullets = $(contentEditable).find("li");
        const bullet = $(sel.focusNode)
          .closest("li")
          .get(0);
        const lastBullet = bullets.get(0);

        if (bullet === lastBullet) {
          result = true;
        }
      } else {
        if (container.classList.contains("new-paragraph")) {
          container = LeaseEditorService.findClosestContainerAbove(
            container
          ).get(0);
        }

        const textNodes = getAllTextNodes(container);
        const firstTextNode = textNodes[0];
        const firstTextNodeRect = firstTextNode.parentElement.getClientRects();
        let currentTextNode = sel.anchorNode;
        
        if (sel.anchorNode.nodeType === Node.ELEMENT_NODE) {
          currentTextNode = getAllTextNodes(currentTextNode)[0]
        }
        
        const currentTextNodeRect = currentTextNode.parentElement.getClientRects();

        for (let i = 0; i < currentTextNodeRect.length && !result; i++) {
          if (
            Math.abs(currentTextNodeRect[i].top - caretOffset.top) <= THRESHOLD_ERROR && 
            currentTextNodeRect[i].top == firstTextNodeRect[0].top
          ) {
            result = true;
          }
        }

        return result;
      }

      return result;
    }

    function isLastLineInContainer(caretOffset, isBullets) {
      var sel = rangy.getSelection();
      var currBottomY = caretOffset.top + caretOffset.height;
      var result = false;
      var THRESHOLD_ERROR = 4;

      const contentEditable = $(sel.focusNode).closest(".editable");
      let container = LeaseEditorService.findClosestContainer(
        sel.focusNode
      ).get(0);

      if (isBullets) {
        const bullets = $(contentEditable).find("li");
        const bullet = $(sel.focusNode)
          .closest("li")
          .get(0);
        const lastBullet = bullets.get(bullets.length - 1);

        if (bullet === lastBullet) {
          result = true;
        }
      } else {
        if (container.classList.contains("new-paragraph")) {
          container = LeaseEditorService.findClosestContainerAbove(
            container
          ).get(0);
        }

        const textNodes = getAllTextNodes(container);
        const lastTextNode = textNodes[textNodes.length - 1];
        const lastTextNodeRect = lastTextNode.parentElement.getClientRects();
        let currentTextNode = sel.anchorNode;
        
        if (sel.anchorNode.nodeType === Node.ELEMENT_NODE) {
          currentTextNode = getAllTextNodes(currentTextNode)[0]
        }
        
        const currentTextNodeRect = currentTextNode.parentElement.getClientRects();

        for (let i = 0; i < currentTextNodeRect.length && !result; i++) {
          if (
            Math.abs(currentTextNodeRect[i].top - caretOffset.top) <= THRESHOLD_ERROR && 
            currentTextNodeRect[i].top == lastTextNodeRect[lastTextNodeRect.length - 1].top
          ) {
            result = true;
          }
        }

        return result;
      }

      return result;
    }

    function moveCaretStraightDown(isBullets) {
      let y;
      let sel = rangy.getSelection();
      let currentContentEditable = LeaseEditorService.findClosestContentEditable(
        sel.focusNode,
        true
      );
      let caretOffset = $(currentContentEditable).caret("offset");
      let newContentEditable = currentContentEditable;

      if (isLastLineInContainer(caretOffset, isBullets)) {
        const block = currentContentEditable.get(0).getBlockData();
        const nextBlock = block.getNextVisibleBlock();

        if (nextBlock) {
          if (!newContentEditable) {
            return;
          }

          newContentEditable = nextBlock.containingNode;
        }
      }

      let caretContainer = LeaseEditorService.findClosestContainer(
        newContentEditable
      ).get(0);
      let caretTop = caretOffset.top;
      let spans = getAllTextNodesParents(caretContainer);

      for (let spanIndex = 0; spanIndex < spans.length && !y; spanIndex++) {
        let caretContainerRects = spans[spanIndex].getClientRects();

        for (
          let rectIndex = 0;
          rectIndex < caretContainerRects.length;
          rectIndex++
        ) {
          if (Math.round(caretTop) < Math.round(caretContainerRects[rectIndex].top)) {
            let newCaretTop = caretContainerRects[rectIndex].top;
            if (
              caretContainerRects[rectIndex].height > 0 &&
              caretTop < newCaretTop
            ) {
              y = newCaretTop + caretContainerRects[rectIndex].height / 2;

              if (y < caretTop) {
                y = null;
              } else {
                break;
              }
            }
          }
        }
      }

      if (caretContainer) {
        const x = window.reservedCaretPosition;

        scopeVar.getCaretNewPlacement(spans, parseFloat(x), y, true);
      }
    }

    function moveCaretStraightUp(isBullets) {
      let y;
      let sel = rangy.getSelection();
      let currentContentEditable = LeaseEditorService.findClosestContentEditable(
        sel.focusNode,
        true
      );
      let caretOffset = $(currentContentEditable).caret("offset");
      let newContentEditable = currentContentEditable;

      if (isFirstLineInContainer(caretOffset, isBullets)) {
        const block = currentContentEditable.get(0).getBlockData();
        const previousBlock = block.getPreviousVisibleBlock();


        if (previousBlock) {
          if (!newContentEditable) {
            return;
          }

          newContentEditable = previousBlock.containingNode;
        }
        if (!newContentEditable) {
          return;
        }
      }

      let caretContainer = LeaseEditorService.findClosestContainer(
        newContentEditable
      ).get(0);
      let caretTop = caretOffset.top;
      let spans = getAllTextNodesParents(caretContainer);

      for (
        let spanIndex = spans.length - 1;
        spanIndex >= 0 && !y;
        spanIndex--
      ) {
        let caretContainerRects = spans[spanIndex].getClientRects();

        for (
          let rectIndex = caretContainerRects.length - 1;
          rectIndex >= 0;
          rectIndex--
        ) {
          if (Math.round(caretTop) > Math.round(caretContainerRects[rectIndex].top)) {
            let newCaretTop = caretContainerRects[rectIndex].top;
            if (
              caretContainerRects[rectIndex].height > 0 &&
              newCaretTop < caretTop
            ) {
              y = newCaretTop + caretContainerRects[rectIndex].height / 2;

              if (y > caretTop) {
                y = null;
              } else {
                break;
              }
            }
          }
        }
      }

      if (caretContainer) {
        const x = window.reservedCaretPosition;

        scopeVar.getCaretNewPlacement(spans, parseFloat(x), y, true);
      }
    }

    /**
     * A continuous operation means that the user is trying to do something
     * across segments, like navigation, using backspace or delete, etc'.
     */
    function isContinuousOperation(isForward, isOnlyHorizontal) {
      var sel = rangy.getSelection();
      var node = sel.focusNode;
      var offset = sel.focusOffset;
      var isContinuous = false;

      if (!isContinuous) {
        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentElement;
        }

        const container = node.closest(".inserted-list-item:not([data-json-id]) table");
        if (container) {
          const cells = container.querySelectorAll("p");
          
          if (isForward) {
            cells[0].classList.add("p--last");

            if (!node.closest(".p--last")) {
              cells[0].classList.remove("p--last");
              return false;
            } else {
              cells[0].classList.remove("p--last");
            }
          } else {
            cells[cells.length - 1].classList.add("p--first");

            if (!node.closest(".p--first")) {
              cells[cells.length - 1].classList.remove("p--first");
              return false;
            } else {
              cells[cells.length - 1].classList.remove("p--first");
            }
          }
        }
      }

      if (!isContinuous) {
        var parent = node;

        if ($(parent).closest(".editable").length === 0) {
          isContinuous = true;
        } else if ($(parent).hasClass("editable")) {
          isContinuous = true;
        } else {
          // Go up the tree
          while (
            !$(parent)
              .parent()
              .hasClass("editable")
          ) {
            parent = parent.parentElement;
          }

          // Go sideways to skip empty spans
          node = parent;
          offset = sel.getRangeAt(0).toCharacterRange(parent).end;
          offset = _.clamp(offset, 0, rangy.innerText(node).length);

          if (isForward) {
            if (node.nextSibling && !$(node.nextSibling).hasClass("EOCE")) {
              node = LeaseEditorService.skipEmptyNodes(node, isForward);
              offset = sel.getRangeAt(0).toCharacterRange(node).end;
            }
          } else {
            if (
              node.previousSibling &&
              !$(node.previousSibling).hasClass("SOCE")
            ) {
              node = LeaseEditorService.skipEmptyNodes(node, isForward);
              offset = sel.getRangeAt(0).toCharacterRange(node).end;
            }
          }
        }
      }

      if (!isContinuous) {
        if (isForward) {
          const text = rangy.innerText(node);
          const trim = _.trimEnd(text);
          const textLength = text.length;
          const textTrimLength = trim.length;
          const whitespace = textLength - textTrimLength;
          var isCaretAtEnd = LeaseEditorService.isEmpty(node)
            ? true
            : offset - whitespace === trim.length;
          var nextSibling = node;
          if (!$(nextSibling).hasClass("EOCE")) {
            nextSibling = LeaseEditorService.skipEmptyNodes(
              node.nextSibling,
              isForward
            );
          }

          if (
            isCaretAtEnd &&
            ($(nextSibling).hasClass("EOCE") ||
              $(nextSibling).attr("contenteditable") === "false")
          ) {
            isContinuous = true;
          }
        } else {
          node = LeaseEditorService.findClosestContentEditable(node).get(0);
          offset = sel.getRangeAt(0).toCharacterRange(node).end;

          var isCaretAtStart =
            offset == 0 ||
            (offset == 1 &&
              (rangy.innerText(node)[0] === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || rangy.innerText(node)[0] === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE));

          if (isCaretAtStart) {
            isContinuous = true;
          }
        }
      }

      if (isOnlyHorizontal) {
        const contentEditable = LeaseEditorService.findClosestContentEditable(
          node
        ).get(0);
        const container = LeaseEditorService.findClosestContainer(node);
        const allContentEditable = LeaseEditorService.findAllContentEditable(
          container
        );

        if (isForward) {
          const lastContentEditable = allContentEditable.get(
            allContentEditable.length - 1
          );

          if (contentEditable === lastContentEditable) {
            isContinuous = false;
          }
        } else {
          const firstContentEditable = allContentEditable.get(0);

          if (contentEditable === firstContentEditable) {
            isContinuous = false;
          }
        }
      }

      return isContinuous;
    }

    /**
     * Moves the caret to the correct content-editable, depending of the direction
     * @param {boolean} isForward     - indicating the direction
     * @param {boolean} isNotAdjacent - indicating if we should search for an adjacent
     *                                  content-editable or not
     */
    function moveCaret(
      isForward,
      isNotAdjacent,
      isForce,
      e,
      element,
      isBullets,
      scopeVar
    ) {
      var sel = rangy.getSelection();

      var closestContentEditable = LeaseEditorService.findClosestContentEditable(
        sel.focusNode
      );
      if (closestContentEditable.length === 0) return;

      if (isNotAdjacent) {
        reserveCaretPosition(element);
        if (isForward) {
          moveCaretStraightDown(isBullets);
        } else {
          moveCaretStraightUp(isBullets);
        }
        e.preventDefault();
        return;
      }

      var endMarker = isForward ? ".SOCE" : ".EOCE";
      var shouldMove =
        isForce || isContinuousOperation(isForward, false) || false;

      if (shouldMove) {
        var newContentEditable;

        const blockData = element.get(0).getBlockData();

        if (isForward) {
          if (contentEditableManager.isCaretAtEndContainer()) {
            const nextBlockData = blockData.getNextVisibleBlock();

            if (nextBlockData) {
              const nextBlock = nextBlockData.containingNode;
    
              if (nextBlock) {
                newContentEditable = nextBlock.querySelector(".editable");
              }
            }
          } else {
            newContentEditable = LeaseEditorService.findNextContentEditable(
              element
            );
          }
        } else {
          if (contentEditableManager.isCaretAtStartContainer()) {
            const previousBlockData = blockData.getPreviousVisibleBlock();

            if (previousBlockData) {
              const previousBlock = previousBlockData.containingNode;
    
              if (previousBlock) {
                newContentEditable = previousBlock.querySelectorAll(".editable");
                newContentEditable = newContentEditable[newContentEditable.length - 1];
              }
            }
          } else {
            newContentEditable = LeaseEditorService.findPreviousContentEditable(
              element
            );
          }
        }

        if (newContentEditable) {
          var oldContainer = LeaseEditorService.findClosestContainer(element);
          var newContainer = LeaseEditorService.findClosestContainer(
            newContentEditable
          );
          var allContentEditableInNewContainer = LeaseEditorService.findAllContentEditable(
            newContainer
          );
          var offset;

          if (oldContainer[0] !== newContainer[0]) {
            e.preventDefault();
          }

          let prevContainer;
          while (LeaseEditorService.isHidden(newContainer)) {
            if (isForward) {
              newContentEditable = LeaseEditorService.findNextContentEditable(
                allContentEditableInNewContainer[
                  allContentEditableInNewContainer.length - 1
                ]
              );
            } else {
              newContentEditable = LeaseEditorService.findPreviousContentEditable(
                allContentEditableInNewContainer[0]
              );
            }

            prevContainer = newContainer;
            newContainer = LeaseEditorService.findClosestContainer(
              newContentEditable
            );
            allContentEditableInNewContainer = LeaseEditorService.findAllContentEditable(
              newContainer
            );

            if (prevContainer[0] == newContainer[0]) {
              break;
            }
          }

          for (var i = 0; i < allContentEditableInNewContainer.length; i++) {
            LeaseEditorService.enableEditingForSegment(
              allContentEditableInNewContainer[i]
            );
            LeaseEditorService.addMarkers(allContentEditableInNewContainer[i]);
          }

          var span = $(newContentEditable).find(endMarker)[0];
          offset = 0;

          if (isForward) {
            // Move to placeholder
            span = LeaseEditorService.skipEmptyNodes(span, true);

            // Place the caret at te start+1 of the found `span`
            scopeVar.placeCaretAtStart(span);

            // Place the caret at the actual text node rather than the `span`
            sel = rangy.getSelection();
            var node = sel.anchorNode;
            if (node.hasChildNodes()) {
              node = sel.anchorNode.childNodes[0];
            }

            scopeVar.placeCaretAtPosition(node, 0 + offset);
          } else {
            // Move to the previous `span`
            span = LeaseEditorService.skipEmptyNodes(span, false);

            // In some cases, the previous element isn't a text field Let's figure it out
            // and act accordingly
            if ($(span).find("span").length > 0) {
              // That sometimes not enough cause sometimes spans are nested, Lets find the
              // last span of all the spans inside the current element
              span = $(span)
                .find("span:last-child")
                .last()[0];
            }

            // Place the caret at te end-1 of the found `span`
            scopeVar.placeCaretAtEnd(span);

            // Place the caret at the actual text node rather than the `span
            sel = rangy.getSelection();
            var node = sel.anchorNode;
            if (node.hasChildNodes()) {
              node =
                sel.anchorNode.childNodes[sel.anchorNode.childNodes.length - 1];
            }

            scopeVar.placeCaretAtPosition(node, node.textContent.length);
          }
        }
      }
    }

    function handleNavigationArrowWithShift(
      e,
      extendBy,
      isNativeBehavior = true
    ) {
      var sel = rangy.getSelection();
      var curr, destElement;
      var focusNode = sel.focusNode;
      var direction =
        window.keyCode === window.ENUMS.KEYS.RIGHT_ARROW ||
        window.keyCode === window.ENUMS.KEYS.DOWN_ARROW ||
        window.keyCode === window.ENUMS.KEYS.END
          ? "forward"
          : "backward";

      if (LeaseEditorService.isBlinkingCaret() && isNativeBehavior) {
        return;
      }

      if ($(focusNode).closest(".editable").length > 0) {
        switch (window.keyCode) {
          case window.ENUMS.KEYS.UP_ARROW: {
            curr = LeaseEditorService.findClosestContentEditable(focusNode);
            destElement = LeaseEditorService.findPreviousContentEditable(
              focusNode
            );
            break;
          }
          case window.ENUMS.KEYS.LEFT_ARROW: {
            curr = LeaseEditorService.findClosestContentEditable(focusNode);
            destElement = LeaseEditorService.findPreviousContentEditable(
              focusNode
            );
            break;
          }
          case window.ENUMS.KEYS.DOWN_ARROW: {
            curr = LeaseEditorService.findClosestContentEditable(focusNode);
            destElement = LeaseEditorService.findNextContentEditable(focusNode);
            break;
          }
          case window.ENUMS.KEYS.RIGHT_ARROW: {
            curr = LeaseEditorService.findClosestContentEditable(focusNode);
            destElement = LeaseEditorService.findNextContentEditable(focusNode);
            break;
          }
        }

        LeaseEditorService.disableEditingForSegment(curr);
        LeaseEditorService.disableEditingForSegment(destElement);
      } else {
        var container = LeaseEditorService.findClosestContainer(focusNode);
        var contentEditables = LeaseEditorService.findAllContentEditable(
          container
        );
        if (contentEditables.length > 0) {
          LeaseEditorService.disableEditingForSegment(contentEditables[0]);
        }
      }

      if (extendBy === "lineboundary") {
        LeaseEditorService.disableEditingForSegment(
          LeaseEditorService.findClosestContentEditable(sel.focusNode)
        );
        sel.nativeSelection.modify("extend", direction, "lineboundary");
        sel.refresh();

        if (is.mac()) {
          e.preventDefault();
        }
      }

      if (extendBy === "paragraphboundary") {
        LeaseEditorService.disableEditingForSegment(
          LeaseEditorService.findClosestContentEditable(sel.focusNode)
        );
        sel.nativeSelection.modify("extend", direction, "paragraphboundary");
        sel.refresh();
      }

      if (extendBy === "word") {
        while (
          (direction === "forward" &&
            sel.focusNode.length === sel.focusOffset) ||
          ((sel.focusNode.nodeType === Node.ELEMENT_NODE &&
            $(sel.focusNode.previousSibling).hasClass("SOCE")) ||
            $(sel.focusNode.nextSibling).hasClass("EOCE")) ||
          (sel.focusNode.nodeType === Node.TEXT_NODE &&
            (sel.focusNode.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || sel.focusNode.textContent === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE))
        ) {
          sel.nativeSelection.modify("extend", direction, "character");
          sel.refresh();
        }

        sel.nativeSelection.modify("extend", direction, "word");
        sel.refresh();
        e.preventDefault();
      }

      if (extendBy === "character") {
        while (
          (sel.focusNode.nodeType === Node.ELEMENT_NODE &&
            $(sel.focusNode.previousSibling).hasClass("SOCE")) ||
          $(sel.focusNode.nextSibling).hasClass("EOCE") ||
          ((sel.focusNode.nodeType === Node.TEXT_NODE &&
            (sel.focusOffset === 0 &&
              $(sel.focusNode.parentElement.previousSibling).hasClass(
                "SOCE"
              ))) ||
            (sel.focusOffset === sel.focusNode.textContent.length &&
              $(sel.focusNode.parentElement.nextSibling).hasClass("EOCE"))) ||
          (sel.focusNode.nodeType === Node.TEXT_NODE &&
            (sel.focusNode.textContent.substring(
              sel.focusOffset - 1,
              sel.focusOffset
            ) === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE || sel.focusNode.textContent.substring(
              sel.focusOffset - 1,
              sel.focusOffset
            ) === window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_SPACE))
        ) {
          sel.nativeSelection.modify("extend", direction, "character");
          sel.refresh();
          e.preventDefault();

          var contentEditable = $(sel.focusNode).closest(".editable");
          var nextContainer;
          var nextContentEditable;

          // If the focusNode is inside a content-editable, let's disable editing
          // on it, otherwise, if the focusNode is outside a content-editable (for
          // example, on a list-item), disable editing for all content-editables
          // inside the focusNode container
          if (contentEditable.length !== 0) {
            if (direction === "forward") {
              nextContentEditable = LeaseEditorService.findNextContentEditable(
                contentEditable
              );
            } else {
              nextContentEditable = LeaseEditorService.findPreviousContentEditable(
                contentEditable
              );
            }
          } else {
            nextContainer = LeaseEditorService.findClosestContainer(
              $(sel.focusNode)
            );
            nextContentEditable = LeaseEditorService.findAllContentEditable(
              nextContainer
            );
            if (nextContentEditable.length > 0) {
              nextContentEditable = nextContentEditable.get(0);
            }
          }
          if (nextContentEditable) {
            LeaseEditorService.disableEditingForSegment(nextContentEditable);
          }
        }
      }
    }

    function setElementStyles(element, computedStyle, defaultValues) {
      if (defaultValues) {
        element.style.fontWeight = computedStyle.fontWeight === "" ? defaultValues.fontWeight: computedStyle.fontWeight;
        element.style.fontStyle = computedStyle.fontStyle === "" ? defaultValues.fontStyle: computedStyle.fontStyle;
        element.style.textDecoration = computedStyle.textDecoration === "" ? defaultValues.textDecoration: computedStyle.textDecoration;
        element.style.backgroundColor = (computedStyle.backgroundColor === "" || computedStyle.backgroundColor.contains('rgba(0, 0, 0, 0)')) ? defaultValues.backgroundColor: computedStyle.backgroundColor;
        element.style.color = computedStyle.color === "" ? defaultValues.color: computedStyle.color;
      } else {
        element.style.fontWeight = computedStyle.fontWeight;
        element.style.fontStyle = computedStyle.fontStyle;
        element.style.textDecoration = computedStyle.textDecoration;
        element.style.backgroundColor = computedStyle.backgroundColor;
        element.style.color = computedStyle.color;
      }
    }

    function locateCaretAtStartOfSelection() {
      var node;
      var sel = rangy.getSelection();
      var range = sel.getRangeAt(0);
      node = sel.isBackwards() ? sel.focusNode : sel.anchorNode;
      LeaseEditorService.enableEditingForSegment(
        LeaseEditorService.findClosestContentEditable(node)
      );
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);

      // This can happen when HOME puts you on the list-items (the actual bullets)
      if ($(sel.anchorNode).closest(".editable").length === 0) {
        var container = LeaseEditorService.findClosestContainer(sel.anchorNode);
        var contentEditable = LeaseEditorService.findAllContentEditable(
          container
        );
        contentEditable = contentEditable.get(0);

        if (contentEditable) {
          node = $(contentEditable)
            .find(".SOCE")
            .get(0);
          node = LeaseEditorService.skipEmptyNodes(node, true);
          $rootScope.placeCaretAtStart(node);
        }
      }
    }

    function handleStopDelete() {
      var sel = rangy.getSelection();
      var anchorNode = sel.anchorNode;
      var anchorOffset = sel.anchorOffset;

      if ($(anchorNode).hasClass("editable")) {
        anchorNode = sel.anchorNode.childNodes[sel.anchorOffset - 1];
        anchorOffset = sel.getRangeAt(0).toCharacterRange(anchorNode).start;
      } else if (anchorNode.nodeType === Node.TEXT_NODE) {
        anchorNode = anchorNode.parentElement;
      }

      var CE, closestContainer, lastCEWithText;

      if (rangy.innerText(anchorNode).length === anchorOffset) {
        CE = LeaseEditorService.findClosestContentEditable(anchorNode)[0];
        closestContainer = LeaseEditorService.findClosestContainer(CE);
        lastCEWithText = LeaseEditorService.findAllContentEditable(
          closestContainer
        ).last();
        while (
          LeaseEditorService.isEmpty(lastCEWithText) &&
          !$(CE).is(lastCEWithText)
        ) {
          lastCEWithText = LeaseEditorService.findPreviousContentEditable(
            lastCEWithText
          );
        }

        if ($(CE).is(lastCEWithText)) {
          return (
            rangy.innerText(CE).length - 1 ===
              sel.getRangeAt(0).toCharacterRange(CE).end ||
            LeaseEditorService.isEmpty(CE)
          );
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    function handleStopBackspace() {
      var sel = rangy.getSelection();
      var anchorNode = sel.anchorNode;
      var anchorOffset = sel.anchorOffset;

      if ($(anchorNode).hasClass("editable")) {
        anchorNode = $(sel.anchorNode).find(".PLACEHOLDER")[0];
        anchorOffset = sel.getRangeAt(0).toCharacterRange(anchorNode).start;
      } else if (anchorNode.nodeType === Node.TEXT_NODE) {
        anchorNode = anchorNode.parentElement;
      }

      var closestContainer = LeaseEditorService.findClosestContainer(
        anchorNode
      );
      var firstCE;

      // stop deleting if list-item cannot be deleted
      if (
        $rootScope.isListItem(closestContainer) &&
        !$rootScope.listItemHasContent(closestContainer) &&
        $rootScope.listItemHasChildLists(closestContainer)
      ) {
        return true;
      }

      return false;
    }
    /*
     * Handles keyboard navigation between segments/paragraphs
     */
    function handleNavigationArrow(e, element, isBullets, scopeVar) {
      // Lets make sure that the specified contenteditable is in edit mode
      if (!LeaseEditorService.isSelecting()) {
        LeaseEditorService.enableEditingForSegment(element);

        switch (e.keyCode) {
          // Move Up
          case window.ENUMS.KEYS.UP_ARROW: {
            moveCaret(false, true, false, e, element, isBullets, scopeVar);
            break;
          }
          // Move Left
          case window.ENUMS.KEYS.LEFT_ARROW: {
            LeaseEditorService.skipNonWidthChar("backward");
            moveCaret(false, false, false, e, element, isBullets, scopeVar);
            break;
          }
          // Move Down
          case window.ENUMS.KEYS.DOWN_ARROW: {
            moveCaret(true, true, false, e, element, isBullets, scopeVar);
            break;
          }
          // Move Right
          case window.ENUMS.KEYS.RIGHT_ARROW: {
            LeaseEditorService.skipNonWidthChar("forward");
            LeaseEditorService.skipPageBreak();
            moveCaret(true, false, false, e, element, isBullets, scopeVar);
            break;
          }
        }
      } else {
        switch (e.keyCode) {
          // Move Up
          case window.ENUMS.KEYS.UP_ARROW: {
            locateCaretAtStartOfSelection();
            moveCaret(false, true, false, e, element, isBullets, scopeVar);
            break;
          }
          // Move Left
          case window.ENUMS.KEYS.LEFT_ARROW: {
            locateCaretAtStartOfSelection();
            e.preventDefault();
            break;
          }
          // Move Down
          case window.ENUMS.KEYS.DOWN_ARROW: {
            locateCaretAtEndOfSelection();
            moveCaret(true, true, false, e, element, isBullets, scopeVar);
            break;
          }
          // Move Right
          case window.ENUMS.KEYS.RIGHT_ARROW: {
            locateCaretAtEndOfSelection();
            e.preventDefault();
            break;
          }
        }
      }
    }

    function handleNavigationHomeEndKey(e) {
      var sel = rangy.getSelection();
      var range;
      var direction =
        window.keyCode === window.ENUMS.KEYS.RIGHT_ARROW ||
        window.keyCode === window.ENUMS.KEYS.DOWN_ARROW ||
        window.keyCode === window.ENUMS.KEYS.END
          ? "forward"
          : "backward";
      var isForward = direction === "forward";

      // Disable editing to allow selection
      LeaseEditorService.disableEditingForSegment(
        LeaseEditorService.findClosestContentEditable(sel.focusNode)
      );

      // Make a selection until lineboundry in the direction
      sel.nativeSelection.modify("extend", direction, "lineboundary");
      sel.refresh();

      // Collapse the selection to a caret at the edge according to the direction
      range = sel.getRangeAt(0);
      range.collapse(!isForward);
      range.select();

      //
      if (isForward) {
        if (
          sel.focusNode.nodeType === Node.TEXT_NODE &&
          sel.focusOffset < sel.focusNode.length
        ) {
          sel.move("character", -1);
        }
      } else {
        var node;
        // This can happen when HOME puts you on the list-items (the actual bullets)
        if ($(sel.anchorNode).closest(".editable").length === 0) {
          var container = LeaseEditorService.findClosestContainer(
            sel.anchorNode
          );
          var contentEditable = LeaseEditorService.findAllContentEditable(
            container
          );

          contentEditable = contentEditable.get(0);
          node = $(contentEditable)
            .find(".SOCE")
            .get(0);
          node = LeaseEditorService.skipEmptyNodes(node, isForward);
          $rootScope.placeCaretAtStart(node);
        }
      }

      // Enable editing again to allow the caret to blink
      var contentEditable = LeaseEditorService.findClosestContentEditable(
        sel.focusNode
      );
      LeaseEditorService.enableEditingForSegment(contentEditable);
      $(contentEditable).focus();

      e.preventDefault();
    }

    function handlePageHome(e) {
      const contentEditable = document.querySelector(".editable", true);
      const block = contentEditable.getBlockData();
      block.scrollTo();
      block.placeCaretAtStart();
    }

    function handlePageEnd(e) {
      const contentEditable = Array.from(document.querySelectorAll(".editable", true)).at(-1);
      const block = contentEditable.getBlockData();
      block.scrollTo();
      block.placeCaretAtEnd();
    }

    function handlePageUp(e) {
      // 
    }

    function handlePageDown(e) {
      // 
    }

    /*
     * Handles `backspace`
     * Scenarios:
     *   (1) If there's a selection, we remove it from the DOM using `deleteSelection`
     *       Which, in turn, place the caret at the correct place to allow the user
     *       continue it's work for the expected place
     *   (2) Otherwise, we're falling back to normal backspace
     */
     async function handleBackspace(e, isBullets, element, scopeVar) {
      scopeVar.pauseSearch();

      if (isBullets) {
        var preventDefault =
          !LeaseEditorService.isSelecting() &&
          LeaseEditorService.isFirstBullet();
        if (preventDefault) {
          e.preventDefault();
        }
        LeaseEditorService.enableEditingForSegment(element);
        return;
      }

      if (e.currentTarget.querySelector("img.active")) {
        LeaseEditorService.deleteImg(
          e.currentTarget.querySelector("img.active")
        );
        return;
      }

      var shouldSave = false;
      let sel = rangy.getSelection();
      const blockData = element.getBlockData ? element.getBlockData() : 
      element.get(0).getBlockData();
      

      if (LeaseEditorService.isSelecting()) {
        e.preventDefault();
        await $rootScope.deleteSelection(null, null, true);
      } else if (
        (blockData.blockType === BLOCK_TYPE.PARAGRAPH ||
        blockData.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH) &&
        !blockData.isEmpty() && 
        LeaseEditorService.isIndented() === false &&
        contentEditableManager.isCaretAtStartContainer()
      ) {
        e.preventDefault();
        const previousBlock = blockData.getPreviousVisibleBlock();

        if (previousBlock) {
          await previousBlock.joinWithNextBlock();
        }
      } else if (
        (
          blockData.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH  ||
          (blockData.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM && !blockData.canMoveToLevel('paragraph') )
        ) && 
        LeaseEditorService.shouldDeleteNewListItem() &&
        LeaseEditorService.isIndented() === false
      ) {
        e.preventDefault();
        var prevCE = $(
          LeaseEditorService.findPreviousContentEditable(element)
        ).attr("section-id");

        const prevBlock =blockData.getPreviousVisibleBlock();

        if (prevBlock) {
          prevBlock.placeCaretAtEnd();
        }

        var insertedListItemId = LeaseEditorService.closestNewListItemId(
          element
        );
        scopeVar.listsDeleteListItem(false, false, insertedListItemId);
        shouldSave = true;
      } else if (
        (
          blockData.blockType === BLOCK_TYPE.PARAGRAPH ||
          blockData.blockType === BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH
        ) && 
        blockData.containingNode.tagName !=="TR" && 
        LeaseEditorService.shouldHideEmptyParagraph(element) && 
        LeaseEditorService.isIndented() === false
      ) {
        e.preventDefault();
        var container = LeaseEditorService.findClosestContainer(element);
        LeaseEditorService.hideEmptyParagraph(element, container, true);
        // Move the caret to the end of the previous paragraph
        moveCaret(false, false, true, e, element, isBullets, scopeVar);
        shouldSave = true;
      } else if (
        blockData.blockType !== BLOCK_TYPE.PARAGRAPH &&
        blockData.blockType !== BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH &&
        blockData.canMoveToLevel('paragraph') &&
        contentEditableManager.isCaretAtStartContainer()) {
        blockData.moveToLevel("paragraph");
      } else if (
        $rootScope.isParagraph(sel.focusNode) &&
        contentEditableManager.isCaretAtStartContainer()
      ) {
        e.preventDefault();
        if (
          sel.focusNode.previousElementSibling &&
          sel.focusNode.previousElementSibling.nodeName === "BR" &&
          sel.focusNode.previousElementSibling.classList.contains(
            "new-page-break"
          )
        ) {
          return;
        }
        if (isIndentedParagraph()) {
          handleUnTab(e, scopeVar);
        } else {
          handleJoinParagraphsBackwards(e, element);
        }
      } else if (
        contentEditableManager.isCaretAtStartContainer() && 
        handleStopBackspace(element)
      ) {
        e.preventDefault();
      } else {
        if (isContinuousOperation(false, true)) {
          var prevCE = $(
            LeaseEditorService.findPreviousContentEditable(sel.anchorNode)
          ).get(0);
          if (prevCE) {
            var prevNode = LeaseEditorService.skipEmptyNodes(
              prevCE.childNodes[prevCE.childNodes.length - 1],
              false
            );
            scopeVar.placeCaretAtEnd(prevNode, false);
            LeaseEditorService.deleteNonWidthChars();
          }
        } else {
          // There's no selection, execute the native behavior of `backspace`
          LeaseEditorService.deleteNonWidthChars();
        }
      }

      if (shouldSave) {
        triggerChange(element);
      }
    }

    /*
     * Handles `delete`
     * Scenarios:
     *   (1) If there's a selection, we remove it from the DOM using `deleteSelection`
     *       Which, in turn, place the caret at the correct place to allow the user
     *       continue it's work for the expected place
     *   (2) Otherwise, we're falling back to normal delete
     */
     async function handleDelete(e, isBullets, element, scopeVar) {
      scopeVar.pauseSearch();

      if (isBullets) {
        return;
      }

      if (e.currentTarget.querySelector("img.active")) {
        LeaseEditorService.deleteImg(
          e.currentTarget.querySelector("img.active")
        );
        return;
      }

      var shouldSave = false;
      let sel = rangy.getSelection();
      let focusNode = sel.focusNode;
      const blockData = element.getBlockData ? element.getBlockData() : 
      element.get(0).getBlockData();

      if (!focusNode.nextSibling) {
        focusNode = focusNode.parentElement;
      }

      const paragraphContainer = LeaseEditorService.findClosestContainer(
        focusNode
      ).get(0);
      const mainContainer = LeaseEditorService.findClosestContainerAbove(
        paragraphContainer
      ).get(0);

      const newParagraphs = Array.from(
        mainContainer.querySelectorAll(".new-paragraph")
      );
      let isParagraph = false;

      if (paragraphContainer.classList.contains("new-paragraph")) {
        const paragraphIndex = newParagraphs.indexOf(paragraphContainer);
        isParagraph = paragraphIndex < newParagraphs.length - 1;
      } else {
        const newParagraphs = Array.from(
          paragraphContainer.querySelectorAll(".new-paragraph")
        );
        isParagraph = newParagraphs.length > 0;
      }

      if (LeaseEditorService.isSelecting()) {
        e.preventDefault();
        await $rootScope.deleteSelection(null, null, true);
      } else if (
        !blockData.isEmpty() && 
        contentEditableManager.isCaretAtEndContainer()
      ) {
        e.preventDefault();
        if (blockData) {
          await blockData.joinWithNextBlock();
        }
      } else if (LeaseEditorService.shouldHideEmptyParagraph(element)) {
        e.preventDefault();
        var container = LeaseEditorService.findClosestContainer(element);
        LeaseEditorService.hideEmptyParagraph(element, container, true);
        shouldSave = true;

        // Move the caret to the end of the previous paragraph
        moveCaret(true, false, true, e, element, isBullets, scopeVar);
      } else if (isParagraph && isCaretInEndParagraph()) {
        e.preventDefault();
        handleJoinParagraphsForward(e, element);
      } else if (handleStopDelete()) {
        e.preventDefault();
      } else {
        if (isContinuousOperation(true, true)) {
          e.preventDefault();
          // Move the caret before deleting the next character
          moveCaret(true, false, true, e, element, isBullets, scopeVar);
          // Delete the current character after moving the caret
          sel = rangy.getSelection();
          $rootScope.setSelection(sel.anchorNode, 0, sel.anchorNode, 1);
          await $rootScope.deleteSelection();
        } else {
          // There's no selection, execute the native behavior of `backspace`
        }
      }

      if (shouldSave) {
        triggerChange(element);
      }
    }

    /* ------------------------------------------------------------------------------------------------------------------ */

    this.contentEditableLink = (scope, element, attrs, ngModel) => {
      if (contentEditableManager && !contentEditableManager.inited) {
        contentEditableManager.init(
          $compile,
          FreeTextService,
          $rootScope,
          LeaseEditorService,
          $q,
          $timeout,
          CleanFreeTextService,
          ApiService
        );
      }
      const nodeName = element[0].nodeName.toLowerCase();

      if (
        $rootScope.adminMode &&
        (nodeName === "unlocked-var" || nodeName === "building-variable")
      ) {
        return false;
      }

      if (!ngModel && attrs.contenteditableType === "building-variable") {
        if ($rootScope.adminMode) {
          setTimeout(function() {
            $rootScope.placeCaretAtStart(element.get(0));
          });
        }
        return false;
      }

      if (element.hasClass("SOCE") || element.hasClass("EOCE")) {
        return false;
      }
      /* ========== Variables =========== */

      var scopeVar = $rootScope.findAppScope(scope);
      var isBullets = attrs.bullet === "true";
      // TODO: remove from scopeVar and use the window only
      scopeVar.addPageBreak = window.pageLayersManager.addPageBreak;

      var html;
      if (element.find("ng-transclude").length > 0) {
        html = element.find("ng-transclude").get(0).innerHTML;
        element.get(0).innerHTML = html;
      } else {
        html = element.get(0).innerHTML;
      }

      element.find('span[style*="inline-block"]:not(.word-indent)').each(function() {
        if ($(this).text().length === 0) {
          $(this).html("&nbsp;");
        }
      });

      // Since we're overriding `$render` we have to update the DOM ourselves whenever
      // `ngModel` changes
      var isInit = false;
      if (ngModel != null) {
        ngModel.$render = function() {
          // It evaluates & flattens special variables We delay the execution of this code
          // to make sure special variables are ready for us

          if (!ngModel.$modelValue) {
            // The provision is coming from the document
            if (!isInit) {
              // Initial setup

              element.get(0).innerHTML = html;
              isInit = true;
            } else {
              var sel = rangy.getSelection();
              var bookmark = sel.getBookmark(
                $('[section-id="' + attrs.sectionId + '"]').parent()[0]
              );
              // test if we are inside a <DEL> element in version control , if so stop update

              // The provision was deleted, let's update the DOM to the default provision
              element.get(0).innerHTML =
                $rootScope.defaultProvisions[attrs.sectionId].html;
              sel.moveToBookmark(bookmark);
            }
            $(element).removeClass("lp-dirty");
          } else {
            $(element).addClass("lp-dirty");
          }

          // We have to recompile the node since it might contain angular directives
          $compile(element.contents())(scope);

          const buildingVars = element.find("building-variable");
          if (buildingVars.length === 0) {
            flattenAndSave(element, scopeVar, attrs, scope, ngModel);
          } else {
            if ($rootScope.isBuildingLanguageNeeded) {
              $rootScope.$watch("isBuildingLanguageLoaded", () => {
                if ($rootScope.isBuildingLanguageLoaded) {
                  flattenAndSave(element, scopeVar, attrs, scope, ngModel);
                }
              });
            } else {
              flattenAndSave(element, scopeVar, attrs, scope, ngModel);
            }
          }
        };
      } else {
        $compile(element.contents())(scope);

        //resolve promise of inserted-list-item when compiling is done.
        if (
          $rootScope.listItemsPromises &&
          $rootScope.listItemsPromises[attrs.sectionId]
        ) {
          $rootScope.listItemsPromises[attrs.sectionId].resolve();
        }
      }

      if (!scopeVar.editingEnabled) {
        return;
      }

      /* ========== View methods =========== */

      function handleHardBreakInternal(e) {
        const isAtParagraphEnd = contentEditableManager.isCaretAtParagraphEnd();
        const sel = rangy.getSelection();
        let anchor = sel.focusNode;

        if (anchor.nodeType === Node.TEXT_NODE) {
          anchor = anchor.parentElement;
        }

        if(!anchor.closest('[pid]')){
          return;
        }

        const block = anchor.getBlockData
          ? anchor.getBlockData()
          : anchor.get(0).getBlockData();

        const container = LeaseEditorService.findClosestContainer(anchor);

        if (
          block && 
          block.blockType !== BLOCK_TYPE.PARAGRAPH &&
          block.blockType !== BLOCK_TYPE.INSERTED_LIST_ITEM_PARAGRAPH &&
          block.isEmpty()
        ) {
          if (block.canMoveToPreviousLevel()) {
            block.moveToPreviousLevel();
          } else if (block.canMoveToLevel("paragraph")) {
            block.moveToLevel("paragraph");
          }
        } else if (
          block && 
          !block.isEmpty() &&
          block.blockType != BLOCK_TYPE.NESTED_PARAGRAPH &&
          contentEditableManager.isCaretAtEndContainer() ||
          ((block.tableNode || container.get(0).closest("table")) &&
            isAtParagraphEnd)
        ) {
          block.addNextBlock();
          return;
        } else {
          if (block.blockType === BLOCK_TYPE.NESTED_PARAGRAPH) {
            createParagraph(e);
          } else {
            if(block.isEmpty()){
              block.addNextBlock();
            }
            else{
              block.splitBlock();
            }
          }
          return;
        }


        window.track.event(new EnterHardBreakEvent({
          context: $rootScope.getContext()
        }));
      }
      
      async function handleHardBreak(e) {
        scopeVar.pauseSearch();

        if (isBullets) {
          return;
        }

        e.preventDefault();

        if (LeaseEditorService.isSelecting()) {
          await $rootScope.deleteSelection(handleHardBreakInternal, null, true);
        } else {
          handleHardBreakInternal(e);
        }
      }

      
      // TODO: Clicking the arrows without letting go (continously) doesn't work
      // anymore because I moved it to keyup was that keydown was triggered without
      // the selection that was being made
      // Probably need to handle navigation in both places
      function handleHotKeyDown(e) {
        if (leaseIfManager.isInFormManager) {
          if (
            !element[0].closest("[free-text]") ||
            element[0].closest("[data-Complicated-Condition]") ||
            element[0].closest('[contenteditable="false"]') ||
            element[0].closest(".lp-hide-element")
          ) {
            e.preventDefault();
            return;
          }

          const caretNode = LeaseEditorService.getCaretNode();

          if (
            !caretNode.closest("[free-text]") ||
            caretNode.closest("[data-Complicated-Condition]") ||
            caretNode.closest('[contenteditable="false"]') ||
            caretNode.closest(".lp-hide-element")
          ) {
            e.preventDefault();
            return;
          }
        }

        window.isDecorationAction = false;
        window.altKey = e.altKey;
        window.ctrlKey = e.ctrlKey;
        window.metaKey = e.metaKey;
        window.shiftKey = e.shiftKey;
        window.keyCode = e.keyCode;

        if (window.ENUMS.KEY_CODES.indexOf(e.keyCode) === -1) {
          return;
        }

        // Windows+Linux / Mac has different keyboard combinations for text editing
        // We need to support both
        var isMac = is.mac();

        // each action might change the caret position, so we reset it in each keydown
        resetReservedCaretPosition(e);

        // This allows us to disable specific operations related
        // to text editing (for example, we won't want to allow
        // users to delete texts in rent-tables)
        var isEditable = true;

        if (LeaseEditorService.isSelecting()) {
          var contentEditable = LeaseEditorService.findAllContentEditableInSelection();
          if (contentEditable.length === 0) {
            isEditable = false;
          }
        }

        if (isMac) {
          if (e.shiftKey && e.altKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrowWithShift(e, "word");
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW: {
                handleNavigationArrowWithShift(e, "paragraphboundary", false);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.shiftKey && e.metaKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.KEY_A: {
                handleSelectAll(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_Z: {
                handleRedo(e);
                break;
              }
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrowWithShift(e, "lineboundary", false);
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW: {
                handleNavigationArrowWithShift(e, "paragraphboundary", false);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.shiftKey && e.ctrlKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.SPACE_BAR: {
                handleHardSpace(e);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.altKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW: {
                handleNavigationArrow(e, element, isBullets, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrow(e, element, isBullets, scopeVar);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.metaKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.ENTER: {
                if (isEditable && !scopeVar.disablePageBreak) {
                  scopeVar.addPageBreak();
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_B: {
                if (isEditable) {
                  window.isDecorationAction = true;
                  handleToggleBold(e, element, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_I: {
                if (isEditable) {
                  window.isDecorationAction = true;
                  handleToggleItalic(e, element, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_U: {
                if (isEditable) {
                  window.isDecorationAction = true;
                  handleToggleUnderline(e, element, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_Z: {
                handleUndo(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_F: {
                handleFind(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_E: {
                handleEditorFind(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_L: {
                handleAlign(e, "left");
                break;
              }
              case window.ENUMS.KEYS.KEY_R: {
                handleAlign(e, "right");
                break;
              }
              case window.ENUMS.KEYS.KEY_E: {
                handleAlign(e, "center");
                break;
              }
              case window.ENUMS.KEYS.KEY_J: {
                handleAlign(e, "justify");
                break;
              }
              case window.ENUMS.KEYS.KEY_X: {
                handleCut(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_C: {
                handleCopy(e);
                break;
              }
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationHomeEndKey(e);
                break;
              }
              case window.ENUMS.KEYS.HOME: {
                handlePageHome(e);
                break;
              }
              case window.ENUMS.KEYS.END: {
                handlePageEnd(e);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.shiftKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.ESCAPE:
              case window.ENUMS.KEYS.F1:
              case window.ENUMS.KEYS.F2:
              case window.ENUMS.KEYS.F3:
              case window.ENUMS.KEYS.F4:
              case window.ENUMS.KEYS.F5:
              case window.ENUMS.KEYS.F6:
              case window.ENUMS.KEYS.F7:
              case window.ENUMS.KEYS.F8:
              case window.ENUMS.KEYS.F9:
              case window.ENUMS.KEYS.F10:
              case window.ENUMS.KEYS.F11:
              case window.ENUMS.KEYS.F12:
              case window.ENUMS.KEYS.SHIFT: {
                e.preventDefault();
                break;
              }
              case window.ENUMS.KEYS.CAPS_LOCK:
              case window.ENUMS.KEYS.PAGE_UP:
              case window.ENUMS.KEYS.PAGE_DOWN: {
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW:
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrowWithShift(e, "character");
                break;
              }
              case window.ENUMS.KEYS.HOME:
              case window.ENUMS.KEYS.END: {
                handleNavigationArrowWithShift(e, "lineboundary", false);
                break;
              }
              case window.ENUMS.KEYS.ENTER: {
                if (isEditable) {
                  handleSoftBreak(e, isBullets);
                }
                break;
              }
              case window.ENUMS.KEYS.BACKSPACE: {
                if (isEditable) {
                  handleBackspace(e, isBullets, element, scopeVar);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              case window.ENUMS.KEYS.DELETE: {
                if (isEditable) {
                  handleDelete(e, isBullets, element, scopeVar);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              case window.ENUMS.KEYS.TAB: {
                if (isEditable) {
                  handleUnTab(e, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.SINGLE_QUOTE: {
                if (isEditable) {
                  handleSmartQuotes(e, scopeVar);
                }
                break;
              }
              default: {
                if (isEditable) {
                  handleNormalKey(e, scopeVar, isBullets);
                }
              }
            }
          } else {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.ESCAPE:
              case window.ENUMS.KEYS.F1:
              case window.ENUMS.KEYS.F2:
              case window.ENUMS.KEYS.F3:
              case window.ENUMS.KEYS.F4:
              case window.ENUMS.KEYS.F5:
              case window.ENUMS.KEYS.F6:
              case window.ENUMS.KEYS.F7:
              case window.ENUMS.KEYS.F8:
              case window.ENUMS.KEYS.F9:
              case window.ENUMS.KEYS.F10:
              case window.ENUMS.KEYS.F11:
              case window.ENUMS.KEYS.F12: {
                e.preventDefault();
                break;
              }
              case window.ENUMS.KEYS.CAPS_LOCK: {
                break;
              }
              case window.ENUMS.KEYS.PAGE_UP: {
                handlePageUp(e, element, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.PAGE_DOWN: {
                handlePageDown(e, element, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.HOME:
              case window.ENUMS.KEYS.END: {
                handleNavigationHomeEndKey(e);
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW:
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrow(e, element, isBullets, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.ENTER: {
                if (isEditable) {
                  handleHardBreak(e);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              case window.ENUMS.KEYS.TAB: {
                if (isEditable) {
                  handleTab(e, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.SINGLE_QUOTE: {
                if (isEditable) {
                  handleSmartQuotes(e, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.BACKSPACE: {
                if (isEditable) {
                  handleBackspace(e, isBullets, element, scopeVar);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              case window.ENUMS.KEYS.DELETE: {
                if (isEditable) {
                  handleDelete(e, isBullets, element, scopeVar);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              default: {
                if (isEditable) {
                  handleNormalKey(e, scopeVar, isBullets);
                }
              }
            }
          }
        } else {
          if (e.shiftKey && e.ctrlKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrowWithShift(e, "word");
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW: {
                handleNavigationArrowWithShift(e, "paragraphboundary", false);
                break;
              }
              case window.ENUMS.KEYS.SPACE_BAR: {
                handleHardSpace(e);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.ctrlKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.ENTER: {
                if (isEditable && !scopeVar.disablePageBreak) {
                  scopeVar.addPageBreak();
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_A: {
                handleSelectAll(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_B: {
                if (isEditable) {
                  window.isDecorationAction = true;
                  handleToggleBold(e, element, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_I: {
                if (isEditable) {
                  window.isDecorationAction = true;
                  handleToggleItalic(e, element, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_U: {
                if (isEditable) {
                  window.isDecorationAction = true;
                  handleToggleUnderline(e, element, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.KEY_Z: {
                handleUndo(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_Y: {
                handleRedo(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_F: {
                handleFind(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_E: {
                handleEditorFind(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_L: {
                handleAlign(e, "left");
                break;
              }
              case window.ENUMS.KEYS.KEY_R: {
                handleAlign(e, "right");
                break;
              }
              case window.ENUMS.KEYS.KEY_E: {
                handleAlign(e, "center");
                break;
              }
              case window.ENUMS.KEYS.KEY_J: {
                handleAlign(e, "justify");
                break;
              }
              case window.ENUMS.KEYS.KEY_X: {
                handleCut(e);
                break;
              }
              case window.ENUMS.KEYS.KEY_C: {
                handleCopy(e);
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW: {
                handleNavigationArrow(e, element, isBullets, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrow(e, element, isBullets, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.HOME: {
                handlePageHome(e);
                break;
              }
              case window.ENUMS.KEYS.END: {
                handlePageEnd(e);
                break;
              }
              default: {
                break;
              }
            }
          } else if (e.shiftKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.ESCAPE:
              case window.ENUMS.KEYS.F1:
              case window.ENUMS.KEYS.F2:
              case window.ENUMS.KEYS.F3:
              case window.ENUMS.KEYS.F4:
              case window.ENUMS.KEYS.F5:
              case window.ENUMS.KEYS.F6:
              case window.ENUMS.KEYS.F7:
              case window.ENUMS.KEYS.F8:
              case window.ENUMS.KEYS.F9:
              case window.ENUMS.KEYS.F10:
              case window.ENUMS.KEYS.F11:
              case window.ENUMS.KEYS.F12:
              case window.ENUMS.KEYS.SHIFT: {
                e.preventDefault();
                break;
              }
              case window.ENUMS.KEYS.CAPS_LOCK:
              case window.ENUMS.KEYS.PAGE_UP:
              case window.ENUMS.KEYS.PAGE_DOWN: {
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW:
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrowWithShift(e, "character");
                break;
              }
              case window.ENUMS.KEYS.ENTER: {
                if (isEditable) {
                  handleSoftBreak(e, isBullets);
                }
                break;
              }
              case window.ENUMS.KEYS.HOME:
              case window.ENUMS.KEYS.END: {
                handleNavigationArrowWithShift(e, "lineboundary", false);
                break;
              }
              case window.ENUMS.KEYS.TAB: {
                if (isEditable) {
                  handleUnTab(e, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.SINGLE_QUOTE: {
                if (isEditable) {
                  handleSmartQuotes(e, scopeVar);
                }
                break;
              }
              default: {
                if (isEditable) {
                  handleNormalKey(e, scopeVar, isBullets);
                }
              }
            }
          } else {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.ESCAPE:
              case window.ENUMS.KEYS.F1:
              case window.ENUMS.KEYS.F2:
              case window.ENUMS.KEYS.F3:
              case window.ENUMS.KEYS.F4:
              case window.ENUMS.KEYS.F5:
              case window.ENUMS.KEYS.F6:
              case window.ENUMS.KEYS.F7:
              case window.ENUMS.KEYS.F8:
              case window.ENUMS.KEYS.F9:
              case window.ENUMS.KEYS.F10:
              case window.ENUMS.KEYS.F11:
              case window.ENUMS.KEYS.F12: {
                e.preventDefault();
                break;
              }
              case window.ENUMS.KEYS.CAPS_LOCK: {
                break;
              }
              case window.ENUMS.KEYS.PAGE_UP: {
                handlePageUp(e, element, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.PAGE_DOWN: {
                handlePageDown(e, element, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.HOME:
              case window.ENUMS.KEYS.END: {
                handleNavigationHomeEndKey(e);
                break;
              }
              case window.ENUMS.KEYS.UP_ARROW:
              case window.ENUMS.KEYS.RIGHT_ARROW:
              case window.ENUMS.KEYS.DOWN_ARROW:
              case window.ENUMS.KEYS.LEFT_ARROW: {
                handleNavigationArrow(e, element, isBullets, scopeVar);
                break;
              }
              case window.ENUMS.KEYS.ENTER: {
                if (isEditable) {
                  handleHardBreak(e);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              case window.ENUMS.KEYS.TAB: {
                if (isEditable) {
                  handleTab(e, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.SINGLE_QUOTE: {
                if (isEditable) {
                  handleSmartQuotes(e, scopeVar);
                }
                break;
              }
              case window.ENUMS.KEYS.BACKSPACE: {
                if (isEditable) {
                  handleBackspace(e, isBullets, element, scopeVar);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              case window.ENUMS.KEYS.DELETE: {
                if (isEditable) {
                  handleDelete(e, isBullets, element, scopeVar);
                  maintainNewParagraphsStyles(element);
                }
                break;
              }
              default: {
                if (isEditable) {
                  handleNormalKey(e, scopeVar, isBullets);
                }
              }
            }
          }
        }
      }

      function handleHotKeyUp(e) {

        if(leaseIfManager.isInFormManager){
          if(element[0].closest('[data-Complicated-Condition]')){
            e.preventDefault();
            return;
          }
        }

        // Windows+Linux / Mac has different keyboard combinations for text editing
        // We need to support both
        var isMac = is.mac();
        if (isMac) {
          if (e.shiftKey && e.ctrlKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.KEY_0: {
                handleCtrlShift0(e);
                break;
              }
              default: {
                break;
              }
            }
          }
        } else {
          if (e.shiftKey && e.ctrlKey) {
            switch (e.keyCode) {
              case window.ENUMS.KEYS.KEY_0: {
                handleCtrlShift0(e);
                break;
              }
              default: {
                break;
              }
            }
          }
        }
      }

      // TODO: move this function to LeaseEditorService
      function createParagraph(e) {
        scopeVar = $rootScope.findAppScope(scope);
        scopeVar.pauseSearch();

        if (e) {
          e.preventDefault();
        }
        LeaseEditorService.skipMarkers();

        let sel = rangy.getSelection();
        let caretInStartParagraph = LeaseEditorService.isCaretInStartParagraph(p);
        let originalAnchorOffset = sel.anchorOffset;
        let originalAnchorNode = sel.anchorNode;
        let style;
        let anchorNode = sel.anchorNode;
        let span = anchorNode;

        if (anchorNode.nodeType === Node.TEXT_NODE) {
          span = anchorNode.parentElement;
        }

        if (anchorNode.nodeName.toLowerCase() === "soft-return") {
          let placeHolder = anchorNode.parentElement.querySelector(
            ".PLACEHOLDER"
          );

          scopeVar.placeCaretAtEnd(placeHolder);
          sel = rangy.getSelection();
        }

        if (originalAnchorNode.nodeType === Node.TEXT_NODE) {
          originalAnchorNode = originalAnchorNode.parentElement;
        }

        if (
          originalAnchorOffset === 0 &&
          originalAnchorNode.previousElementSibling &&
          originalAnchorNode.previousElementSibling.classList.contains(
            "SOCE"
          ) &&
          originalAnchorNode.getListItemInfoFromNode()
        ) {
          let freeTextNode = originalAnchorNode.closest("[free-text]");
          if (
            freeTextNode && 
            freeTextNode.previousElementSibling && 
            freeTextNode.previousElementSibling.style && 
            freeTextNode.previousElementSibling.style.font.contains("7pt")
          ) {
            originalAnchorOffset = -1;
          }
        }

        if (sel) {
          const container = LeaseEditorService.findClosestContainer(
            sel.anchorNode
          ).get(0);
          style = LeaseEditorService.getStyle(container);
        }

        let contentEditable = LeaseEditorService.findClosestContentEditable(
          sel.focusNode
        );
        let container = LeaseEditorService.findClosestContainer(
          contentEditable
        );
        let allContentEditableInContainer = LeaseEditorService.findAllContentEditable(
          container
        );
        let contentEditableIndex = allContentEditableInContainer.index(
          contentEditable
        );
        let computedStyle = window.getComputedStyle(span);

        // We don't support creating new-paragraphs where there's logic after
        // the location of the caret (logic = there are other free-text in the same container)
        if (contentEditableIndex < allContentEditableInContainer.length - 1) {
          return;
        }

        // Insert line break
        var p = document.createElement("p");
        p.innerHTML = `${window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE}<hard-return class="hard-return--new"></hard-return>`;
        p.setAttribute("style", style);
        p.style.marginLeft = 0;
        p.style.textIndent = 0;
        p.style.fontWeight = computedStyle.fontWeight;
        p.style.fontStyle = computedStyle.fontStyle;
        p.style.textDecoration = computedStyle.textDecoration;

        p.setAttribute("data-paragraph-indent", 0);
        p.setAttribute("data-text-indent", 0);
        p.setAttribute("data-text-align", "start");
        p.setAttribute("data-original-paragraph-indent", "0pt");
        p.setAttribute(
          "data-original-text-indent",
          p.style.textIndent || "0pt"
        );
        p.setAttribute("data-original-text-align", "start");
        p.classList.add("new-paragraph");

        let node = sel.focusNode;

        if (node.nodeType === Node.TEXT_NODE) {
          node = node.parentElement;
        }

        insertAtCaret(p, false);
        save();
        $rootScope.placeCaretAtEnd(p);

        let parent = p;
        let iterator = p.nextSibling;
        let next;

        if (parent.closest(".editable")) {
          if (originalAnchorOffset !== 0) {
            do {
              while (iterator) {
                next = iterator.nextSibling;

                if (iterator.nodeType === Node.ELEMENT_NODE) {
                  if (
                    !iterator.classList.contains("EOCE") &&
                    !iterator.classList.contains("new-paragraph") &&
                    !iterator.hasAttribute("data-nested-paragraph-index") &&
                    iterator.nodeName.toLowerCase() !== "hard-return"
                  ) {
                    p.insertBefore(
                      iterator,
                      p.childNodes[p.childNodes.length - 1]
                    );
                  }
                } else if (iterator.nodeType === Node.TEXT_NODE) {
                  if (
                    !(
                      (parent.textContent === "﻿" || parent.textContent === "​") &&
                      iterator &&
                      iterator.nodeName.toLowerCase() === "hard-return"
                    )
                  ) {
                    let computedStyle = window.getComputedStyle(iterator.parentElement);
                    let newParent = document.createElement('span');
                    newParent.appendChild(iterator);
                    setElementStyles(newParent, computedStyle);
                    p.insertBefore(
                      newParent,
                      p.childNodes[p.childNodes.length - 1]
                    );
                  }
                }

                iterator = next;
              }

              $rootScope.placeCaretAtStart(p);
              parent = parent.parentElement;
              iterator = parent.nextSibling;
              if (iterator && iterator.nodeName === 'TD') {
                break;
              }
            } while (!parent.classList.contains("editable"));
          }
        }

        const savedSelection = rangy.saveSelection();

        flattenNestedParagraphs(originalAnchorOffset, element, scopeVar);

        if (
          container &&
          container.get(0).querySelectorAll(".new-paragraph").length === 1
        ) {
          moveOriginalHardReturn(container.get(0));
        }

        rangy.restoreSelection(savedSelection, true);

        if (caretInStartParagraph) {
          $rootScope.placeCaretAtStart(p.nextElementSibling);
        }

        $rootScope.placeCaretAtStart(p.lastChild.previousSibling);

        return p;
      }

      if (!$rootScope.createParagraph) {
        $rootScope.createParagraph = createParagraph;
      }

      function preparePastedText(text) {
        return text.replace(/\n/g, " ");
      }

      function normalizePastedLineBreaks(text) {
        return text.replace(/<br \/>/g, "<br />&#xFEFF;");
      }

      async function handlePasteInline(contentEditable, event, text) {
        // grab the parent's format (check one level upper as well)
        var format =
        $(contentEditable)
          .parent()
          .attr("format") ||
        ($(contentEditable)
          .parent()
          .parent() &&
          $(contentEditable)
            .parent()
            .parent()
            .attr("format"));

        if (format === "allowed") {
          // Casto Exhibit D-1, B  and B-1 cleanups
          text = text.replaceAll("DeltaViewInsertion", "");
          text = text.replaceAll("MsoNormal", "");
          text = text.replaceAll("mso-bookmark:", "");
          text = text.replaceAll("<a", "<span");
          text = text.replaceAll("</a>", "</span>");
          text = text.replaceAll("color:black", "");
          text = text.replaceAll("color:windowtext", "");
          text = text.replaceAll("font-size:10.0pt;", "");
        } else {
          // Create a dummy element in the dom, take only the part we need (Previously we
          // took the entire pasted HTML)
          $("<div id='dummy'/>").appendTo("body");
          $("#dummy").html("<div class='paste'>" + text + "</div>");
          $("#dummy")
            .find('br[class*="Apple-"]')
            .remove();

          // Remove all styles except the following
          $("#dummy")
            .find(".paste")
            .find("p + span[style]")
            .each(function() {
              const p = document.createElement("p");
              this.parentElement.insertBefore(p, this);
              p.appendChild(this);
            });

          $("#dummy")
            .find(".paste")
            .find("[style], p")
            .each(function() {
              const unsupportedForeColor = [
                "rgb(21, 133, 212)",
                "rgb(0, 0, 0)"
              ];
              const unsupportedBackColor = [
                "rgb(227, 234, 247)",
                "rgb(160, 234, 207)",
                "rgb(253, 165, 214)",
                "rgb(255, 255, 255)",
                "rgb(253, 192, 226)",
                "rgb(255, 86, 148)",
                "rgb(169, 236, 211)",
                "rgb(46, 206, 146)"
              ];

              var keepStyle, $el, tagName;
              $el = $(this);
              keepStyle = {};
              tagName = $el.get(0).tagName;

              if (tagName.toLowerCase() === "p") {
                const container = LeaseEditorService.findClosestContainer(
                  contentEditable
                ).get(0);
                const containerStyle = LeaseEditorService.getStyle(
                  container
                );
                const p = $el.get(0);

                $el.attr("style", containerStyle);

                p.style.marginLeft = 0;
                p.style.textIndent = 0;
                p.setAttribute("data-paragraph-indent", 0);
                p.setAttribute("data-text-indent", 0);
                p.setAttribute("data-text-align", "start");
                p.setAttribute("data-original-paragraph-indent", "0pt");
                p.setAttribute("data-original-text-indent", "0pt");
                p.classList.add("new-paragraph");

                if ($el.prop("style")["text-align"]) {
                  keepStyle["text-align"] = $(this).prop("style")[
                    "text-align"
                  ];
                }
                if ($el.prop("style")["text-indent"]) {
                  keepStyle["text-indent"] = $(this).prop("style")[
                    "text-indent"
                  ];
                }
                if ($el.prop("style")["margin-left"]) {
                  keepStyle["margin-left"] = $(this).prop("style")[
                    "margin-left"
                  ];
                }
                if ($el.prop("style")["margin-top"]) {
                  keepStyle["margin-top"] = $(this).prop("style")[
                    "margin-top"
                  ];
                }
                if ($el.prop("style")["margin-bottom"]) {
                  keepStyle["margin-bottom"] = $(this).prop("style")[
                    "margin-bottom"
                  ];
                }
              }

              if ($el.prop("style")["font-size"]) {
                keepStyle["font-size"] = $(this).prop("style")[
                  "font-size"
                ];
              }
              if ($el.prop("style")["line-height"]) {
                keepStyle["line-height"] = $(this).prop("style")[
                  "line-height"
                ];
              }
              if ($el.prop("style")["font-weight"]) {
                keepStyle["font-weight"] = $(this).prop("style")[
                  "font-weight"
                ];
              }
              if ($el.prop("style")["font-style"]) {
                keepStyle["font-style"] = $(this).prop("style")[
                  "font-style"
                ];
              }
              if ($el.prop("style")["text-align"]) {
                keepStyle["text-align"] = $(this).prop("style")[
                  "text-align"
                ];
              }
              if (
                $el.prop("style")["color"] &&
                unsupportedForeColor.indexOf($el.prop("style")["color"]) ===
                  -1
              ) {
                keepStyle["color"] = $(this).prop("style")["color"];
              }
              if (
                $el.prop("style")["background-color"] &&
                unsupportedBackColor.indexOf(
                  $el.prop("style")["background-color"]
                ) === -1
              ) {
                keepStyle["background-color"] = $(this).prop("style")[
                  "background-color"
                ];
              }
              if ($el.prop("style")["text-decoration"]) {
                keepStyle["text-decoration"] = $(this).prop("style")[
                  "text-decoration"
                ];
              }

              $el.removeAttr("style").css(keepStyle);
            });

          // Remove all diff deletions
          $("#dummy")
            .find(".diff-item.diff-item--static")
            .remove();

          document.querySelector("#dummy").removeWhitespace();

          var toSanitize = preparePastedText(
            $("#dummy")
              .find(".paste")
              .html()
          );

          // Clean the HTML and leave only the following elements & attributes
          text = text.replace(/{{.*?}}/g, '');
          text = sanitizeHtml(toSanitize, {
            allowedTags: [
              "b",
              "i",
              "u",
              "p",
              "span",
              "br",
              "input",
              "font",
              "sup",
              "sub"
            ],
            transformTags: {
              h1: "span",
              h2: "span",
              h3: "span",
              h4: "span",
              h5: "span",
              h6: "span",
              strong: function(tagName, attribs) {
                return {
                  tagName: "span",
                  attribs: {
                    style: "font-weight: bold;"
                  }
                };
              },
              b: function(tagName, attribs) {
                return {
                  tagName: "span",
                  attribs: {
                    style: "font-weight: bold;"
                  }
                };
              },
              em: function(tagName, attribs) {
                return {
                  tagName: "span",
                  attribs: {
                    style: "text-style: italic;"
                  }
                };
              },
              i: function(tagName, attribs) {
                return {
                  tagName: "span",
                  attribs: {
                    style: "text-style: italic;"
                  }
                };
              },
              u: function(tagName, attribs) {
                return {
                  tagName: "span",
                  attribs: {
                    style: "text-decoration: underline;"
                  }
                };
              }
            },
            allowedAttributes: {
              "*": ["style"],
              "input": ['type', 'value'],
              p: ["data-*", "class"]
            },
            allowedClasses: {
              p: ["new-paragraph"]
            },
            selfClosing: ["br"],
            exclusiveFilter: function(frame) {
              return (
                (frame.tag === "b" ||
                  frame.tag === "i" ||
                  frame.tag === "u") &&
                !frame.text.trim()
              );
            }
          });

          // Remove white space between HTML nodes
          text = text.trim();

          // Add non-width-characters after `<br>`s to be able to put the cursor there
          text = normalizePastedLineBreaks(text);

          // Remove the dummy element from the DOM
          $("#dummy").remove();
        }

        if (text.trim()) {
          var html;

          if ($rootScope.adminMode) {
            html =
              "<span class='paste-elements override-color'>" +
              text +
              "</span>";
          } else {
            html = "<span class='paste-elements'>" + text + "</span>";
          }

          var sel, range;

          if (
            rangy.getSelection &&
            (sel = rangy.getSelection()).rangeCount
          ) {
            range = sel.getRangeAt(0);
            var htmlObj = $(html);

            if ($rootScope.adminMode) {
              $(htmlObj)
                .find("p")
                .addClass("override-color");
            } else {
              if (format === "allowed") {
                $(htmlObj)
                  .find("p")
                  .css("font-size", "10pt");
              }
            }

            function paste() {
              let sel;
              let range; 
              
              sel = rangy.getSelection();
              range = sel.getRangeAt(0);

              // Insert the pasted text instead
              var node = htmlObj[0];
              range.insertNode(node);

              if (format === "allowed") {
                $(contentEditable)
                  .find("table")
                  .each(function() {
                    var style = $(this).attr("style");
                    $(this).attr("style", style + ";width:100%");
                  });
              }

              let container = LeaseEditorService.findClosestContainer(
                node
              )[0];
              let first = container.querySelector(".new-paragraph--first");
              if (first) {
                let paragraphs = container.querySelectorAll(
                  "p:not(.new-paragraph--first).new-paragraph"
                );
                for (let i = 0; i < paragraphs.length; ++i) {
                  first.appendChild(paragraphs[i]);
                }
              }

              flattenNestedParagraphs(null, element, scopeVar);
              let span = document.querySelector(".paste-elements");
              let isEmptyParagraph = true;

              // Add text after caret to new-paragraph
              sel = rangy.getSelection();
              let originalAnchorOffset = sel.anchorOffset;
              let firstParagraph = span.querySelector('p');
              if (firstParagraph) {
                let p = firstParagraph.cloneNode();
                firstParagraph.parentNode.insertBefore(p, firstParagraph.nextSibling);

                let parent = p;
                let iterator = p.nextSibling;
                let next;

                if (parent.closest(".editable")) {
                  if (originalAnchorOffset !== 0) {
                    do {
                      while (iterator) {
                        next = iterator.nextSibling;

                        if (iterator.nodeType === Node.ELEMENT_NODE) {
                          if (
                            !iterator.classList.contains("EOCE") &&
                            !iterator.classList.contains("new-paragraph") &&
                            iterator.nodeName.toLowerCase() !== "hard-return"
                          ) {
                            isEmptyParagraph = false;
                            p.insertBefore(
                              iterator,
                              null
                            );
                          }
                        } else if (iterator.nodeType === Node.TEXT_NODE) {
                          if (
                            !(
                              (parent.textContent === "﻿" || parent.textContent === "​") &&
                              iterator &&
                              iterator.nodeName.toLowerCase() === "hard-return"
                            )
                          ) {
                            isEmptyParagraph = false;
                            let computedStyle = window.getComputedStyle(iterator.parentElement);
                            let newParent = document.createElement('span');
                            newParent.appendChild(iterator);
                            setElementStyles(newParent, computedStyle);
                            p.insertBefore(
                              newParent,
                              null
                            );
                          }
                        }

                        iterator = next;
                      }

                      $rootScope.placeCaretAtStart(p);
                      parent = parent.parentElement;
                      iterator = parent.nextSibling;
                      if (iterator && iterator.nodeName === 'TD') {
                        break;
                      }
                    } while (!parent.classList.contains("editable"));
                  }
                }

                if (isEmptyParagraph) {
                  p.remove();
                }
              }

              span.classList.remove("paste-elements");

              range.collapseAfter(node);
              range.select();
            }

            if (LeaseEditorService.isSelecting()) {
              // Delete the text the user selected before inserting the pasted text
              await $rootScope.deleteSelection(paste, true);
            } else {
              paste();
              save();
            }
          }
        }
      }
      
      // TODO[refactor]:
      async function handlePaste(contentEditable, event) {
        scopeVar.pauseSearch();

        window.track.event(new PasteEvent({
          context: $rootScope.getContext()
        }));

        let clipboardData = (event.originalEvent || event).clipboardData;
        let useNewPaste = false;

        if (clipboardData && clipboardData.types.indexOf("text/rtf") > -1) {
          useNewPaste = true;
        }

        if (contentEditableManager.isCaretAtEndContainer() && useNewPaste) {
          clipboardManager.handlePaste(event, scopeVar, $compile, $q, LeaseEditorService, ApiService, $timeout);
          return;
        }

        var text = "";

        if (typeof clipboardData != "undefined") {
          // In most standard cases, the clipboard data will be of type `text/html`
          // but in-case it doesn't, let's fall back to `text/plain`, otherwise, the
          // text extracted from the clipboard will be empty
          var type = "text/plain";
          if (clipboardData.types.indexOf("text/html") > -1) {
            type = "text/html";
          }

          // --------- IMAGES PASTE IN ---------
          var copiedData = clipboardData.items[0];
          if (copiedData.type.indexOf("image") === 0) {
            const imageFile = copiedData.getAsFile();
            const appScope = $rootScope.findAppScope();
            appScope.uploadImage(imageFile);
          } else {
            // ------- TEXT PASTE IN --------
            if (clipboardData) {
              text = clipboardData.getData(type);
            } else if (window.clipboardData && window.clipboardData.getData) {
              text = window.clipboardData.getData(type);
            }

            handlePasteInline(contentEditable, event, text);
          }
        } else {
          alert(
            "The current browser does not support 'paste' button functionality.\nPlease use C" +
              "trl + V key combination."
          );
        }
      }

      // TODO[refactor]:
      function deleteProvision(sectionId) {
        var currSelection = $rootScope.getSelectionData();
        scopeVar.addToHistory({
          id: sectionId,
          desc: "deleted free-text [section id: " + sectionId + "]",
          redo: (function(scopeVar, sectionId) {
            return function() {
              var deferred = $q.defer();
              scopeVar.deleteFreeText(sectionId).then(function() {
                if (
                  !element[0].closest(".diff-item") &&
                  !element[0].querySelector(".diff-item")
                ) {
                  ngModel.$setViewValue(undefined);
                }
                ngModel.$render();
                scopeVar.appendEditingSpaceToLeaseVars();
                deferred.resolve({ selection: currSelection });
              });
              return deferred.promise;
            };
          })(scopeVar, sectionId),
          undo: (function(
            scopeVar,
            FreeTextService,
            $rootScope,
            ngModel,
            html,
            element,
            $compile,
            scope
          ) {
            return function() {
              var deferred = $q.defer();
              element = $(element); // refresh element in case it was deleted at some point
              scopeVar.allChangesSaved = false;
              scopeVar.update();
              var onCreate = function(freeText) {
                // set that FreeText back into the ngModel so the database ID can be tracked for
                // updates

                if (scopeVar.shouldRecompile[sectionId]) {
                  scopeVar.shouldRecompile[sectionId] = false;
                  element.html(html);
                  $compile(element.contents())(scope);
                }

                ngModel.$setViewValue(freeText);

                if (freeText && freeText.timestamp === window.__FREE_TEXT_TIMESTAMP__) {
                  scopeVar.allChangesSaved = true;
                }
                
                deferred.resolve();
              };

              const timestamp = new Date().getTime();

              window.__FREE_TEXT_TIMESTAMP__ = timestamp;

              var freeText = new FreeTextService({
                section_id: sectionId,
                lease_id: $rootScope.getLeaseId(),
                amendment_id: $rootScope.getAmendmentId(),
                text: html,
                timestamp,
              });

              if ($rootScope.adminMode) {
                onCreate(freeText);
              } else {
                freeText.create().then(onCreate);
              }
              return deferred.promise;
            };
          })(
            scopeVar,
            FreeTextService,
            $rootScope,
            ngModel,
            ngModel.$modelValue.text,
            element,
            $compile,
            scope
          )
        });
      }

      // TODO[refactor]:
      function createProvision(sectionId, changedProvision) {
        var currSelection = $rootScope.getSelectionData();

        scopeVar.addToHistory({
          id: sectionId,
          desc: "created free-text [section id: " + sectionId + "]",
          redo: (function(
            scopeVar,
            FreeTextService,
            $rootScope,
            ngModel,
            html,
            element,
            $compile,
            scope
          ) {
            return function() {
              var deferred = $q.defer();
              element = $(element); // refresh element in case it was deleted at some point
              scopeVar.allChangesSaved = false;
              scopeVar.update();
              var onCreate = function(freeText) {
                // set that FreeText back into the ngModel so the database ID can be tracked for
                // updates

                if (scopeVar.shouldRecompile[sectionId]) {
                  scopeVar.shouldRecompile[sectionId] = false;
                  element.html(html);
                  $compile(element.contents())(scope);
                }

                ngModel.$setViewValue(freeText);
                scopeVar.allChangesSaved = true;
                $timeout(function() {
                  deferred.resolve({ selection: currSelection });
                }, 200);
              };

              var freeText = new FreeTextService({
                section_id: sectionId,
                lease_id: $rootScope.getLeaseId(),
                amendment_id: $rootScope.getAmendmentId(),
                text: html
              });

              if ($rootScope.adminMode) {
                onCreate(freeText);
              } else {
                freeText.create().then(onCreate);
              }
              return deferred.promise;
            };
          })(
            scopeVar,
            FreeTextService,
            $rootScope,
            ngModel,
            changedProvision,
            element,
            $compile,
            scope
          ),
          undo: (function(scopeVar, sectionId) {
            return function() {
              var deferred = $q.defer();
              element = $(element); // refresh element in case it was deleted at some point
              scopeVar.deleteFreeText(sectionId).then(function() {
                deferred.resolve();
                LeaseEditorService.setDeletedParagraphVisibility(element);
                scopeVar.structureChanged();
              });
              return deferred.promise;
            };
          })(scopeVar, sectionId)
        });
      }

      // TODO[refactor]:
      function updateProvision(sectionId, changedProvision) {
        var currSelection = $rootScope.getSelectionData();

        function getUpdateFunc(
          scopeVar,
          ngModel,
          $compile,
          scope,
          element,
          changedProvision
        ) {
          return function() {
            var deferred = $q.defer();

            if (!ngModel.$modelValue) {
              return Promise.resolve();
            }

            element = $("[section-id='" + element.attr("section-id") + "']"); // refresh element in case it was deleted at some point
            scopeVar.allChangesSaved = false;

            if (
              ngModel.$modelValue.text.hashCode() !==
              changedProvision.hashCode()
            ) {
              ngModel.$modelValue.text = changedProvision;
              // update the resource on the server
              var updateCallback = function(resource) {
                if (scopeVar.shouldRecompile[sectionId]) {
                  scopeVar.shouldRecompile[sectionId] = false;
                  element.html(changedProvision);
                  LeaseEditorService.setControlCharacters(element);
                  $compile(element.contents())(scope);
                  LeaseEditorService.setDeletedParagraphVisibility(element);
                  scopeVar.structureChanged();
                }

                if (resource && resource.timestamp === window.__FREE_TEXT_TIMESTAMP__) {
                  scopeVar.allChangesSaved = true;
                }
                
                deferred.resolve({ selection: currSelection });
              };

              if ($rootScope.adminMode) {
                updateCallback();
              } else {
                const url = "/api/free_texts/" + ngModel.$modelValue.id;
                const timestamp = new Date().getTime();

                window.__FREE_TEXT_TIMESTAMP__ = timestamp;

                FreeTextService.$put(url, {
                  ...ngModel.$modelValue,
                  timestamp,
                }).then(
                  updateCallback
                );
              }
            } else {
              deferred.resolve();
              scopeVar.allChangesSaved = true;
            }
            return deferred.promise;
          };
        }

        scopeVar.addToHistory({
          id: attrs.sectionId,
          desc: "modified free-text [section id: " + sectionId + "]",
          redo: getUpdateFunc(
            scopeVar,
            ngModel,
            $compile,
            scope,
            element,
            changedProvision
          ),
          undo: getUpdateFunc(
            scopeVar,
            ngModel,
            $compile,
            scope,
            element,
            ngModel.$modelValue.text
          )
        });
      }

      var handleChange = _.debounce(function() {
        if ($('[section-id="' + attrs.sectionId + '"]').length == 0) {
          return;
        }

        $(element).addClass("lp-dirty");

        let defaultProvision = scopeVar.defaultProvisions[attrs.sectionId];
        let changedProvision = CleanFreeTextService.cleanHTMLElement(
          element.get(0)
        );
        let changedProvisionHashCode = LeaseEditorService.prepareForComparison(
          changedProvision
        ).hashCode();

        if ($rootScope.adminMode) {
          if (
            defaultProvision &&
            defaultProvision.hashCode === changedProvisionHashCode
          ) {
            element.removeAttr("override");
          } else {
            element.attr("override", "true");
          }
        }
      }, window.CONSTS.WAIT * 4);

      var save = _.debounce(function() {
        if ($('[section-id="' + attrs.sectionId + '"]').length == 0) {
          return;
        }

        $(element).addClass("lp-dirty");

        var defaultProvision = scopeVar.defaultProvisions[attrs.sectionId];
        let changedProvision = CleanFreeTextService.cleanHTMLElement(
          element.get(0)
        );
        let changedProvisionWithVersionControlTags;
        let hasVersionControlTags = false;
        if (scopeVar.diffgram.on) {
          changedProvisionWithVersionControlTags = CleanFreeTextService.cleanHTMLElement(
            element.get(0),
            true
          );
          hasVersionControlTags =
            changedProvision !== changedProvisionWithVersionControlTags;
        }

        var changedProvisionHashCode = LeaseEditorService.prepareForComparison(
          changedProvision
        ).hashCode();

        changedProvision = hasVersionControlTags
          ? changedProvisionWithVersionControlTags
          : changedProvision;

        if ($rootScope.adminMode) {
          if (
            defaultProvision &&
            defaultProvision.hashCode === changedProvisionHashCode
          ) {
            element.removeAttr("override");
          } else {
            element.attr("override", "true");
          }
        }
        // This keeps the segment editable if theres a selection for example, when
        // selecting and clicking ctrl+b
        if (LeaseEditorService.isSelecting()) {
          LeaseEditorService.enableEditingForSegment(element);
        }

        if (!hasProvisionChanged(ngModel)) {
          // There's no override to this provision yet, let's create an override
          createProvision(attrs.sectionId, changedProvision);
        } else {
          if (
            defaultProvision &&
            defaultProvision.hashCode === changedProvisionHashCode
          ) {
            // If the change equals to the default provision, delete the override to this
            // provision
            deleteProvision(attrs.sectionId);
          } else {
            // Otherwise, update the existing override to this provision
            updateProvision(attrs.sectionId, changedProvision);
          }
        }

        window.track.event(new ChangeProvisionEvent({
          context: $rootScope.getContext()
        }));
      }, window.CONSTS.WAIT * 4);

      /* ===================== */

      function registerEventHandlers() {
        const elementRef = $(element);

        async function handleImage(file) {
          return new Promise((resolve, reject) => {
            var imageParagraph;
            var p = createParagraph();
            // we dont allow insertion of image if not last free-text
            if (!p) {
              return;
            }
            // check if in the middle of free-text:
            // then we need another P for the image
            if (p.textContent.replace(/﻿/g, "").length) {
              imageParagraph = createParagraph();
            } else {
              // we can use the created paragraph
              imageParagraph = p;
            }
            imageParagraph.classList.add("image-container");
            imageParagraph.setAttribute("contenteditable", "false");

            var imgElement = document.createElement("img");
            imageParagraph.prepend(imgElement);
            LeaseEditorService.bindImgEvents($(imgElement), scope);
            var img = new Image();
            img.src = file;

            img.onload = function() {
              // downscale image to fit the page size if it's larger
              if (img.width > imageParagraph.offsetWidth) {
                var imgWidth = imageParagraph.offsetWidth;
                // use same proportion for height
                var imgHeight = (imgWidth / img.width) * img.height;
                LeaseEditorService.resizeBase64Image(
                  file,
                  imgWidth,
                  imgHeight,
                  function(newSrc) {
                    imgElement.src = newSrc;
                    imgElement.setAttribute("data-original-src", newSrc);
                    resolve();
                  }
                );
              } else {
                imgElement.src = file;
                imgElement.setAttribute("data-original-src", file);
                resolve();
              }
            };
          });
        }

        async function uploadImageHandler(e, file) {
          if (typeof file === "string") {
            await handleImage(file);
          } else {
            for (let i = file.length - 1; i >= 0; i--) {
              await handleImage(file[i]);
            }

            const appScope = $rootScope.findAppScope();
            appScope.histRegisterBatch(appScope.histIndex, file.length);
          }

          save();

          window.track.event(new InsertImageEvent({
            context: $rootScope.getContext()
          }));
        }
        elementRef.on("uploadImage", uploadImageHandler);

        function afterSaveHandler(
          e,
          freeText,
          action,
          shouldRecompile,
          promise
        ) {
          if (action === "create" || (action === "update" && !ngModel.$modelValue)) {
            // set that FreeText back into the ngModel so the database ID can be tracked for updates

            if (shouldRecompile) {
              element.html(freeText.text);
              $compile(element.contents())(scope);
            }
            ngModel.$setViewValue(freeText);
            ngModel.$modelValue.type = "paste";
          } else if (action === "update") {
            if (shouldRecompile) {
              element.html(freeText.text);
              $compile(element.contents())(scope);
              ngModel.$modelValue.text = freeText.text;
            }
          } else {
            if (
              !element[0].closest(".diff-item") &&
              !element[0].querySelector(".diff-item")
            ) {
              ngModel.$setViewValue(undefined);
            }
            ngModel.$render();
          }

          LeaseEditorService.setDeletedParagraphVisibility(element);
          promise.resolve();
        }

        elementRef.on("afterSave", afterSaveHandler);
        elementRef.on("lp:clausebook:insert", insertClauseHandler);
        elementRef.on("lp:text:insert", insertTextHandler);
        elementRef.on("lp:contenteditable:change", contentEditableChangeHandler);

        function contentEditableChangeHandler(e) {
          window.activityDetector.active();

          handleChange();
        }

        function contentEditableChangeHandler(e) {
          window.activityDetector.active();

          handleChange();
        }

        function insertClauseHandler(event, params) {
          handlePasteInline(element, event, params.html);
        }

        function insertTextHandler(event, params) {
          handlePasteInline(element, event, params.text);
        }

        function contentEditableSaveHandler(e) {
          window.activityDetector.active();

          save();
        }
        elementRef.on("change input", contentEditableSaveHandler);

        function contentMenuHandler(e) {
          window.activityDetector.active();

          // [liork] This is kinda hacky but the easiest way I could find to manage
          // conflicting executions of enable/disable contenteditable
          LeaseEditorService.enableEditingForSegment(element);

          let counter = 0;
          const timer = setInterval(() => {
            if (counter < 5) {
              counter++;
              LeaseEditorService.enableEditingForSegment(element);
            } else {
              clearInterval(timer);
            }
          }, window.CONSTS.WAIT * 2);
        }
        elementRef.on("contextmenu", contentMenuHandler);

        function clickHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          window.activityDetector.active();

          LeaseEditorService.enableEditingForSegment(element);
          resetReservedCaretPosition(e);
        }
        elementRef.on("click", clickHandler);

        function isEditingAction(e) {
          var navigationKeys = [
            window.ENUMS.KEYS.ESCAPE,
            window.ENUMS.KEYS.F1,
            window.ENUMS.KEYS.F2,
            window.ENUMS.KEYS.F3,
            window.ENUMS.KEYS.F4,
            window.ENUMS.KEYS.F5,
            window.ENUMS.KEYS.F6,
            window.ENUMS.KEYS.F7,
            window.ENUMS.KEYS.F8,
            window.ENUMS.KEYS.F9,
            window.ENUMS.KEYS.F10,
            window.ENUMS.KEYS.F11,
            window.ENUMS.KEYS.F12,
            window.ENUMS.KEYS.HOME,
            window.ENUMS.KEYS.END,
            window.ENUMS.KEYS.CAPS_LOCK,
            window.ENUMS.KEYS.PAGE_UP,
            window.ENUMS.KEYS.PAGE_DOWN,
            window.ENUMS.KEYS.SHIFT,
            window.ENUMS.KEYS.UP_ARROW,
            window.ENUMS.KEYS.RIGHT_ARROW,
            window.ENUMS.KEYS.DOWN_ARROW,
            window.ENUMS.KEYS.LEFT_ARROW,
            window.ENUMS.KEYS.NUM_LOCK,
            window.ENUMS.KEYS.SCROLL_LOCK,
            window.ENUMS.KEYS.INSERT
          ];
          return !(
            e.ctrlKey ||
            e.metaKey ||
            _.includes(navigationKeys, e.keyCode)
          );
        }

        function keyDownHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          window.activityDetector.active();

          e.stopPropagation();
          e.stopImmediatePropagation();
          $rootScope.saveSelection(isEditingAction(e));

          // Hide the inline-editor bubble when the user starts typing
          if (scopeVar.inline && scopeVar.inline.on) {
            scopeVar.inline.hideInline();
            scopeVar.safeApply();
          }

          if (LeaseEditorService.isSelecting()) {
            if (e.shiftKey) {
              LeaseEditorService.disableEditingForSegment(element);
            }
          }
          LeaseEditorService.addMarkers(element);
          handleHotKeyDown(e);
        }
        elementRef.on("keydown", keyDownHandler);

        function keyUpHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          window.activityDetector.active();

          e.stopPropagation();
          e.stopImmediatePropagation();

          if (isBullets) {
            LeaseEditorService.adjustBulletsColor();
          }

          if (!e.shiftKey) {
            setTimeout(function() {
              if (LeaseEditorService.isSelecting()) {
                LeaseEditorService.enableEditingForSegment(element);
              }
              if (
                (!window.isDecorationAction ||
                LeaseEditorService.isSelecting()) && !window.allowSetDecoration
              ) {
                scopeVar.setDecoration();
              }
            }, window.CONSTS.WAIT * 2);
          }

          LeaseEditorService.addMarkers(element);

          handleHotKeyUp(e);
        }
        elementRef.on("keyup", keyUpHandler);

        function focusHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          window.activityDetector.active();

          // Setting up the label for the currently edited field
          if (scopeVar.labels) {
            let editedTextField = null;
            if(leaseIfManager.isInFormManager){              
                editedTextField = splitVariable(
                  element
                    .closest("[lease-if]")
                    .attr("lease-if") || ""
                )[0];              
            }

            if (editedTextField) {
              $rootScope.editedField = scopeVar.labels[editedTextField];
            }
          }

          e.stopPropagation();
          e.stopImmediatePropagation();

          const container = LeaseEditorService.findClosestContainer(
            element
          ).get(0);
          scopeVar.setOriginalStyle(container);

          LeaseEditorService.addMarkers(element);

          // Save a reference for the currently edited content-editable
          scopeVar.currentlyEditedNode = element[0];
        }
        elementRef.on("focus", focusHandler);

        function blurHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          $rootScope.editedField = "";
        }
        elementRef.on("blur", blurHandler);

        if (leaseOriginalRef) {
          function leaseOriginalPasteHandler(e) {
            if (scopeVar.diffgram.processing) {
              return;
            }
            window.activityDetector.active();
            e.preventDefault();
            e.stopImmediatePropagation();
            e.stopPropagation();
            handlePaste(element, e);
          }

          leaseOriginalRef.addEventListener('paste', leaseOriginalPasteHandler);
        }

        function pasteHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          window.activityDetector.active();

          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
          handlePaste(element, e);
        }
        elementRef.on("paste", pasteHandler);

        function selectStartHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          e.stopImmediatePropagation();

          $rootScope.baseSelectionNode = element;
        }
        elementRef.on("selectstart", selectStartHandler);

        const mouseUpHandler = _.debounce(function(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          window.activityDetector.active();

          e.stopImmediatePropagation();
          e.stopPropagation();

          // Turn on/off the decoration options accordingly
          scopeVar.setDecoration();
          if (LeaseEditorService.isSelecting()) {
            setTimeout(function() {
              LeaseEditorService.enableEditingForSegment(element);
            }, window.CONSTS.WAIT * 8);
          }
        }, window.CONSTS.WAIT);
        elementRef.on("mouseup", mouseUpHandler);

        // This handles `mouseup` from anything other than content-editable
        // TODO: this should move outside of the directive
        function leaseModifiedMouseUpHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          e.stopImmediatePropagation();
          e.stopPropagation();

          resetReservedCaretPosition(e);
          $rootScope.saveSelection();
          var sel = rangy.getSelection();
          if (!sel.anchorNode) {
            return;
          }

          scopeVar.resetDecoration();
          setTimeout(function() {
            scopeVar.setDecoration();
          });

          // This event triggers to each of the elements in the document Though, the
          // following code should execute only for a specific element The following check
          // makes sure of that
          var closestContentEditable = $(
            LeaseEditorService.findClosestContentEditable(sel.anchorNode)
          );
          if (
            closestContentEditable.length > 0 &&
            closestContentEditable.attr("section-id") !==
              element.attr("section-id")
          ) {
            return;
          }

          if (LeaseEditorService.isSelecting()) {
            var contentEditableInSelection = LeaseEditorService.findAllContentEditableInSelection();

            setTimeout(function() {
              for (var i = 0; i < contentEditableInSelection.length; i++) {
                LeaseEditorService.enableEditingForSegment(
                  contentEditableInSelection[i]
                );
              }
            }, window.CONSTS.WAIT * 8);
          }
        }
        leaseModifiedRef.on("mouseup", leaseModifiedMouseUpHandler);

        // This handles `keydown` on anything other than content-editable
        // This also triggers on tables (e.g. rent-table)
        // TODO: this should move outside of the directive
        function leaseModifiedKeyDownHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          if (
            e.originalEvent.srcElement.hasAttribute("lp-Ignore-Key-Handler")
          )
            return;
          e.stopImmediatePropagation();
          e.stopPropagation();

          var sel = rangy.getSelection();
          if (!sel.anchorNode) {
            return;
          }

          // This event triggers to each of the elements in the document Though, the
          // following code should execute only for a specific element The following check
          // makes sure of that
          var closestContentEditable = $(
            LeaseEditorService.findClosestContentEditable(sel.anchorNode)
          );
          if (
            closestContentEditable.length > 0 &&
            closestContentEditable.attr("section-id") !==
              element.attr("section-id")
          ) {
            return;
          }

          // Hide the inline-editor bubble when the user starts typing
          if (scopeVar.inline && scopeVar.inline.on) {
            scopeVar.inline.hideInline();
            scopeVar.safeApply();
          }

          if (LeaseEditorService.isSelecting()) {
            if (e.shiftKey) {
              LeaseEditorService.disableEditingForSegment(element);
            }
          }

          // We know we're not sitting on contenteditable,
          // therefore, lets limit `handleHotKeyDown` to only handle
          // non-edit operations

          handleHotKeyDown(e);
        }
        leaseModifiedRef
          .on("keydown", leaseModifiedKeyDownHandler);

        function docKeyDownHandler(e) {
          if (scopeVar.diffgram.processing) {
            return;
          }

          if (
            e.originalEvent.srcElement.hasAttribute("lp-Ignore-Key-Handler")
          )
            return;
          var sel = rangy.getSelection();
          if (!sel.anchorNode) {
            return;
          }

          if (
            $(sel.anchorNode).closest(".lease.lease--original").length === 0
          ) {
            return;
          }

          // Hide the inline-editor bubble when the user starts typing
          if (scopeVar.inline && scopeVar.inline.on) {
            scopeVar.inline.hideInline();
            scopeVar.safeApply();
          }

          if (LeaseEditorService.isSelecting()) {
            if (e.shiftKey) {
              var closestContentEditable = $(
                LeaseEditorService.findClosestContentEditable(sel.anchorNode)
              );

              if ($(closestContentEditable).length > 0) {
                // There's a selection, let's disable editing to allow multi-segment selection
                LeaseEditorService.disableEditingForSegment(
                  closestContentEditable
                );
              }
            }
          }

          handleHotKeyDown(e);
        }
        docRef
          .off("keydown")
          .on("keydown", docKeyDownHandler);
      }

      docRef.ready(registerEventHandlers);
    };
  };

  window.contentEditableManager = new ContentEditableManager();
})();
